import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import DialogWrapper from "components/DialogWrapper";
import Loader from "components/Loader";
import SearchContainer from "components/SearchContainer";
import { getApprvalHeirarchies } from "api/services/approval-levels";
import { DialogProps, ResType } from "types";
import { StyledServiceItem, StyledServicesContainer } from "./styles";

interface Props extends DialogProps {
  onChange: (value: any) => void;
  fromRecurring?: boolean;
}

function SelectApprovalHierarchy({ open, setOpen, onChange, fromRecurring = false }: Props) {
  const [search, setSearch] = useState("");
  const params: any = useParams();

  const { data, isLoading }: ResType = useQuery("approval-hierarchies", getApprvalHeirarchies, {
    enabled: open,
  });

  function getData() {
    let result = data?.data ? [...data?.data] : [];

    if (search) {
      result = result?.filter((item) => item.name?.toLowerCase().includes(search.toLowerCase()));
    }
    if (params?.taskId || fromRecurring) {
      result.unshift({
        module: "Task",
        name: "Allow this Task to Proceed Without Approvals",
        approval: {
          approvalLevels: [],
        },
        removeApproval: true,
      });
    }

    return result;
  }

  const handleClick = (appHier: any) => {
    if (appHier?.approval?.approvalLevels?.length) {
      onChange(appHier);
    } else {
      onChange(null);
    }

    setOpen(false);
  };

  return (
    <DialogWrapper width="lg" open={open} setOpen={setOpen} title="Select Approval Hierarchy">
      {/* <Box display="flex" justifyContent="space-between">
        <TextField
          label="Filter By Type"
          variant="outlined"
          size="small"
          sx={{
            width: 200,
          }}
          select
          fullWidth
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          <MenuItem value="">None</MenuItem>
          <MenuItem value="SERVICE">Service</MenuItem>
          <MenuItem value="TASK">Task</MenuItem>
          <MenuItem value="IPRO">I-Pro</MenuItem>
        </TextField>
        <Box display="flex" gap={1}>
          <SearchContainer
            value={search}
            placeHolder="Search"
            minWidth="300px"
            onChange={setSearch}
          />
        </Box>
      </Box> */}
      {/* <Divider sx={{ mt: 2 }} /> */}      {isLoading ? (
        <Loader />
      ) : (
        <StyledServicesContainer>
          <Box display="flex" justifyContent={"flex-end"} mb={2}>
            <SearchContainer
              value={search}
              placeHolder="Search"
              minWidth="280px" // Adjust minWidth for responsiveness
              onChange={setSearch}
            />
          </Box>
          <Grid container spacing={2}>
            {getData().length ? (
              getData().map((item: any, index: number) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <StyledServiceItem onClick={() => handleClick(item)}>
                    <Box>
                      <Typography variant="caption" color="rgba(0,0,0,0.6)">
                        {item?.module}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color={item?.name === "Allow this Task to Proceed Without Approvals" ? "red" : ""}
                      >
                        {item?.name}
                      </Typography>
                      {!!item?.approval?.approvalLevels?.length && (
                        <Typography mt={1} color="rgba(0,0,0,0.6)" variant="body2">
                          Levels ({item?.approval?.approvalLevels?.length})
                        </Typography>
                      )}
                    </Box>
                  </StyledServiceItem>
                </Grid>
              ))
            ) : (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box mt={2}>
                  <Typography>No Data</Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </StyledServicesContainer>
      )}
    </DialogWrapper>
  );
}

export default SelectApprovalHierarchy;
