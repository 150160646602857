import { cronHttp } from "api/cronHttp";
import { http } from "api/http";

const createClient = (data: any) => {
  return http.post("/client", data);
};

const createClientGroup = (data: any) => {
  return http.post("/clientgroup", data);
};

const getClients = ({ queryKey }: any) => {
  let { limit, offset, ...query } = queryKey[1] || {};
  console.log(query)
  return http.get("/client", {
    params: {
      limit,
      offset,
      ...query,

    },
  });
};

const getClientsWithoutClientGroup = ({ queryKey }: any) => {
  let { limit, offset, clientGroupId, sort, search, query } = queryKey[1] || {};
  return http.get("/client/withoutClients", {
    params: {
      limit,
      offset,
      sort,
      clientGroupId,
      search,
      ...query,
    },
  });
};

const getNonClientGroupClients = ({ queryKey }: any) => {
  let { limit, offset, query } = queryKey[1] || {};
  return http.get("/client/nonclientgroupclient", {
    params: {
      limit,
      offset,
      ...query,
    },
  });
};

const getTaskCreateClients = ({ queryKey }: any) => {
  let { limit, offset, query } = queryKey[1] || {};
  return http.get("/client/task");
};

const getClientsGroup = ({ queryKey }: any) => {
  let { limit, offset, sort, query } = queryKey[1] || {};
  return http.get("/clientgroup/all", {
    params: {
      limit,
      offset,
      sort,
      ...query,
    },
  });
};

const getClientGroup = () => {
  return http.get("/clientgroup");
};

const getAllClients = ({ queryKey }: any) => {
  return http.get("/client/all");
};

const getClient = ({ queryKey }: any) => {
  return http.get(`/client/${queryKey[1]}/details`);
};

const getClientGroupDetails = ({ queryKey }: any) => {
  return http.get(`/clientgroup/${queryKey[1]}/details`);
};

const updateClient = ({ data, id }: any) => {
  return http.patch(`/client/${id}/update`, data);
};

const updateClientGroup = ({ data, id }: any) => {
  return http.patch(`/clientgroup/${id}/update`, data);
};

const updateClientGroupClients = ({ data, id }: any) => {
  return http.patch(`/clientgroup/${id}/update/clients`, data);
};

const createContactPerson = (data: any) => {
  return http.post("/contact-persons", data);
};

const updateContactPerson = ({ id, data }: { data: any; id: any }) => {
  return http.put(`/contact-persons/${id}`, data);
};

const deleteContactPerson = (id: any) => {
  return http.delete(`/contact-persons/${id}`);
};

const importClients = (data: FormData) => {
  return http.post(`/client/import`, data);
};

const bulkDelete = ({ ids }: any) => {
  return http.post(`/client/bulk-delete`, { ids });
};

const bulkGroupDelete = ({ ids }: any) => {
  return http.post(`/clientgroup/bulk-delete`, { ids });
};

const GroupClientsDelete = ({ ids, clientGroupId }: any) => {
  return http.post(`/clientgroup/delete-clients`, { ids, clientGroupId });
};

const uploadProfile = ({ id, data }: any) => {
  return http.put(`/client/image/${id}`, data);
};

const bulkUpdate = ({ ids, data }: any) => {
  return http.patch(`/client/bulk-update`, { ids, ...data });
};

const bulkGroupUpdate = ({ ids, data }: any) => {
  return http.patch(`/clientgroup/bulk-update`, { ids, ...data });
};

const createLead = (data: any) => {
  return http.post("/leads", data);
};

const updateLead = ({ id, data }) => {
  return http.put(`/leads/${id}`, data);
};

const deleteLeads = (ids: number[]) => {
  return http.post(`/leads/delete`, { ids });
};

const getLeads = ({ queryKey }) => {
  return http.get("/leads", { params: { ...queryKey[1] } });
};

const getCompletedTasks = ({ queryKey }) => {
  let { limit, offset, search, ...query } = queryKey[1] || {};
  return http.get(`/tasks/completed-tasks`, {
    params: {
      clientId: queryKey[1]?.clientId,
      limit,
      offset,
      search,
      ...query,
    },
  });
};

const getClientCompletedTasksExport = (data) => {
  return http.post(`/tasks/completed-tasks-export`, data);
};

const getRecurringTasks = ({ queryKey }) => {
  let { limit, offset, search, ...query } = queryKey[1] || {};
  return http.get(`/tasks/recurring-tasks`, {
    params: {
      clientId: queryKey[1].clientId,
      limit,
      offset,
      search,
      ...query,
    },
  });
};

const getRecurringTasksExport = (data) => {
  return http.post(`/tasks/recurring-tasks-export`, data);
};

const getNonRecurringTasks = ({ queryKey }) => {
  let { limit, offset, search, ...query } = queryKey[1] || {};
  return http.get(`/tasks/non-recurring-tasks`, {
    params: {
      clientId: queryKey[1].clientId,
      limit,
      offset,
      search,
      ...query,
    },
  });
};

const getNonRecurringTasksExport = (data) => {
  return http.post(`/tasks/non-recurring-tasks-export`, data);
};

const getUserCompletedTasks = ({ queryKey }) => {
  let { limit, offset, search, sort, query } = queryKey[1] || {};

  return http.get(`/tasks/user-completed-tasks`, {
    params: {
      userId: queryKey[1].userId,
      limit,
      sort,
      offset,
      search,
      ...query,
    },
  });
};

const getDeletedTasks = ({ queryKey }) => {
  let { limit, offset, search, sort, ...query } = queryKey[1] || {};

  return http.get(`/tasks/deleted-tasks`, {
    params: {
      client: queryKey[1]?.client,
      limit,
      offset,
      search,
      sort,
      ...query,
    },
  });
};

const getClientDeletedTasksExport = (senddata) => {
  return http.post(`/tasks/deleted-tasks-export`, senddata);
};

const getTerminatedTasksList = ({ queryKey }) => {
  let { limit, offset, query } = queryKey[1] || {};

  return http.get(`/tasks/terminatedtaskslist`, {
    params: {
      clientId: queryKey[1]?.clientId,
      limit,
      offset,
      ...query,
    },
  });
};

const getTerminatedTasks = ({ queryKey }) => {
  let { limit, offset, search, ...query } = queryKey[1] || {};

  return http.get(`/tasks/terminated-tasks`, {
    params: {
      clientId: queryKey[1].clientId,
      limit,
      offset,
      search,
      ...query,
    },
  });
};

const getClientTerminatedTasksexport = (senddata) => {
  const data = senddata;
  return http.post(`/tasks/terminated-tasks-export`, data);
};

const getDeletedClients = ({ queryKey }) => {
  let { limit, offset, sort, search, query } = queryKey[1] || {};

  return http.get("/client/deleted", {
    params: {
      limit,
      offset,
      sort,
      search,
      ...query,
    },
  });
};

const getDeletedClientGroups = ({ queryKey }) => {
  let { limit, offset, search, sort, query } = queryKey[1] || {};

  return http.get("/clientgroup/deleted", {
    params: {
      limit,
      offset,
      search,
      sort,
      ...query,
    },
  });
};

const restoreClient = (data: any) => {
  return http.post(`/client/${data?.id}/restore`, data);
};

const restoreClientGroup = (id: number) => {
  return http.post(`/clientgroup/${id}/restore`);
};

const restoreTask = ({id, data} : any) => {
  return http.post(`/tasks/${id}/restore`, data);
};

const bulkUpdateClients = (data: FormData) => {
  return cronHttp.post(`/client/bulkupdate`, data);
};

export {
  createClient,
  getClients,
  getClient,
  updateClient,
  createContactPerson,
  updateContactPerson,
  deleteContactPerson,
  importClients,
  bulkDelete,
  bulkUpdate,
  createLead,
  getLeads,
  updateLead,
  deleteLeads,
  getTerminatedTasks,
  getUserCompletedTasks,
  getCompletedTasks,
  getDeletedTasks,
  getDeletedClients,
  restoreClient,
  restoreTask,
  getAllClients,
  getTerminatedTasksList,
  getClientCompletedTasksExport,
  getClientTerminatedTasksexport,
  getClientDeletedTasksExport,
  getRecurringTasks,
  getNonRecurringTasks,
  createClientGroup,
  getClientGroup,
  getClientsGroup,
  getTaskCreateClients,
  getNonClientGroupClients,
  getClientGroupDetails,
  updateClientGroup,
  bulkGroupDelete,
  getDeletedClientGroups,
  restoreClientGroup,
  uploadProfile,
  getRecurringTasksExport,
  getNonRecurringTasksExport,
  bulkGroupUpdate,
  GroupClientsDelete,
  getClientsWithoutClientGroup,
  updateClientGroupClients,
  bulkUpdateClients
};
