import { Box, Button, MenuItem, TextField, Typography } from "@mui/material"
import { exportIncomeTaxReports, getIncomeTaxReports } from "api/services/automation/income-tax";
import { useMutation, useQuery } from "react-query";
import { ResType } from "types";
import Table, { ColumnType } from "components/Table";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import useTitle from "hooks/useTitle";
import { handleError } from "utils/handleError";
import { snack } from "components/toast";
import { getCurrentFormattedDateTime } from "utils";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import sortHandler from "components/Table/SortHandler";
import _ from "lodash";
import { SortDirection } from "data/constants";


const IncomeTaxReports = () => {
    useTitle("ATOM Pro | Sync Status");
    const [page, setPage] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(10);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [selected, setSelected] = useState<any[]>([]);
    const [filters, setFilters] = useState<any>({
        status: null,
        remarks: null,
    });
    const location = useLocation();
    const [sort, setSort] = useState<any>({});
    const state: any = location.state;
    const { data, isLoading }: ResType = useQuery(
        ["Income-tax-reports", { limit: pageCount, offset: page * pageCount, ...filters, sort }],
        getIncomeTaxReports, {
        onSuccess: (res) => {
            setTotalCount(res?.data?.count)
        }
    }
    );


    let defaultColumns: Array<ColumnType> = [
        {
            key: "id",
            title: "Client Name",
            sort: true,
            active: false,
            direction: SortDirection.ASC, default: true,
            render: (rowData: any) => <Typography>{rowData?.autoCredentials?.client?.displayName}</Typography>,
        },
        {
            key: "pan",
            title: "PAN",
            sort: true,
            active: false,
            direction: SortDirection.ASC, default: true,
            render: (rowData: any) => <Typography>{rowData?.autoCredentials?.panNumber}</Typography>,
        },
        {
            key: "status",
            title: "Status",
            sort: true,
            active: false,
            direction: SortDirection.ASC, default: true,
        },
        {
            key: "remarks",
            title: "Remarks",
            sort: true,
            active: false,
            direction: SortDirection.ASC, default: true,
            width: "250px",
        },
        {
            key: "createdAt",
            title: "Last Sync",
            sort: true,
            active: false,
            direction: SortDirection.ASC, default: true,
            render: (row) => {
                return moment(row?.createdAt).format("DD-MM-YYYY h:mm a");
            },
        },

    ];
    const [columns, setColumns] = useState(_.cloneDeep(defaultColumns));
    const handleSort = (v: any) => {
        sortHandler({
            key: v.key,
            columns,
            sortState: sort,
            setSortState: setSort,
            setColumns: setColumns,
        });
    };
    const navigate = useNavigate()
    const handleRowClick = (v: any) => {
        navigate(`/atom-pro/income-tax/incometax/${v?.autoCredentials?.id}/client-status`)
    }

    const statusObject = {
        PENDING: "Pending",
        COMPLETED: "Completed",
        INQUEUE: "Inqueue"
    }

    const remarksObject = {
        "Success": "Success",
        "Invalid Password, Please retry.": "Invalid Password",
        "PAN does not exist, Please register this PAN or try with some other PAN.": "PAN does not exist",
        "Login attempt was unsuccessful due to Mult-Factor Authentication": "Mult-Factor Authentication",
        "Success with Error": "Success with Error"
    }

    const handleStatusChange = (e: any) => {
        setFilters({ ...filters, status: e.target.value });
        setPage(0);
    };

    const handleRemarksChange = (e: any) => {
        setFilters({ ...filters, remarks: e.target.value });
        setPage(0);
    };

    const { mutate: mutate1 } = useMutation(exportIncomeTaxReports, {
        onSuccess: (res: any) => {
            const arr = new Uint8Array(res.data?.data);
            const blob = new Blob([arr], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const file = window.URL.createObjectURL(blob);
            const formattedDateTime = getCurrentFormattedDateTime();

            let link = document.createElement("a");
            link.href = file;
            link.download = `ATOM Pro - Income Tax Last Sync Status ${formattedDateTime}.xlsx`;
            link.click();
            snack.success("Exported Successfully");
        },
        onError: (err: any) => {
            snack.error(err);
            snack.error(handleError(err));
        },
    });
    const handleExport = () => {
        mutate1({
            ...state,
            limit: pageCount, offset: page * pageCount, ...filters
        });
    };

    return (
        <Box m={1}>
            <Box display="flex" justifyContent="space-between">
                <Box display="flex">
                    <Box ml={1} >
                        <TextField
                            label="Status"
                            value={filters.status || ""}
                            sx={{ width: 150 }}
                            onChange={handleStatusChange}
                            size="small"
                            select
                        >
                            <MenuItem key={""} value={""}>
                                Select
                            </MenuItem>
                            {Object.keys(statusObject).map((key, index) => (
                                <MenuItem key={index} value={key}>
                                    {statusObject[key]}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                    <Box ml={1}>
                        <TextField
                            label="Remarks"
                            value={filters.remarks || ""}
                            sx={{ width: 200 }}
                            onChange={handleRemarksChange}
                            size="small"
                            select
                        >
                            <MenuItem key={""} value={""}>
                                Select
                            </MenuItem>
                            {Object.keys(remarksObject).map((key, index) => (
                                <MenuItem key={index} value={key}>
                                    {remarksObject[key]}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                </Box>
                <Box ml={1}>
                    <Button variant="outlined" color="secondary" onClick={handleExport}
                        startIcon={<FileDownloadOutlinedIcon />}
                    >
                        Export
                    </Button>
                </Box>
            </Box>
            <Box mt={1}>
                <Table
                    sx={{ height: "550px" }}
                    pagination={{ totalCount, pageCount, setPageCount, page, setPage }}
                    data={data?.data?.data || []}
                    columns={columns}
                    sortHandler={handleSort}
                    onRowClick={handleRowClick}
                    loading={isLoading}
                />
            </Box>
        </Box>
    )


}

export default IncomeTaxReports