import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Typography, TypographyProps } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box } from "@mui/system";
import { getTitle } from "utils";
import { PriorityEnum } from "data/constants";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';


interface Props extends TypographyProps {
  text: PriorityEnum;
}

function PriorityText({ text, ...props }: Props) {

  switch (text) {
    case PriorityEnum.HIGH:
      return <ArrowUpwardIcon fontSize="small" style={{ color: '#FB0505', marginRight: 10, }} />;
    case PriorityEnum.MEDIUM:
      return <RemoveIcon fontSize='small' style={{ color: '#f17f23', marginRight: 10 }} />;
    case PriorityEnum.LOW:
      return <ArrowDownwardIcon fontSize='small' style={{ color: '#019335', marginRight: 10 }} />;
    case PriorityEnum.NONE:
      return <CloseIcon fontSize='small' style={{ color: '#64B5F6', marginRight: 10 }} />
    default:
      return null
  }


}

export default PriorityText;
