import { Delete } from "@mui/icons-material";
import { DesktopDatePicker } from "@mui/lab";
import { Box, Button, IconButton, MenuItem, TextField, Typography } from "@mui/material";
import DialogWrapper from "components/DialogWrapper";
import { snack } from "components/toast";
import { DateTypes, RecurringFrequency } from "data/constants";
import { halfYears, months, quarters } from "data/periods";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { getTitle } from "utils";
import { getFinancialYears } from "utils/getFinancialYears";
import { handleKeyDown } from "utils/keyDownPrevent";
import { createTaskDefaultValues } from "validations/createTask";

function RecurringFrequencyDetails(props: any) {
  const { open, setOpen, recurringState, setRecurringState, saveState, setSaveState } = props;
  const [startPeriods, setStartPeriods] = useState<string[]>([]);
  const [openDatePicker, setOpenDatePicker] = useState(Array(recurringState.dates.length).fill(false));
  const [forFocus, setForFocus] = useState(Array(recurringState.dates.length).fill(true));
  const currentFinancialYear = createTaskDefaultValues.financialYear;
  
  useEffect(() => {
    setOpenDatePicker(Array(recurringState.dates.length * 3).fill(false));
    setForFocus(Array(recurringState.dates.length * 3).fill(true));
  }, [recurringState.dates.length]);

  useEffect(() => {
    if(!recurringState.dates.length){
      const FY = () => recurringState.financialYear ? () => recurringState.financialYear : currentFinancialYear;
      const SP = recurringState?.frequency === "monthly" ? months[0] : recurringState?.frequency === "quaterly" ? quarters[0] : recurringState?.frequency === "half_yearly" ? halfYears[0] : "";
      initializeStateFromDetails(
        currentFinancialYear,
        () => recurringState.financialYear,
        recurringState.startPeriod
      );
    }
  },[recurringState])

  useEffect(() => {
    initializeStateFromDetails(
      currentFinancialYear,
      () => recurringState.financialYear,
      recurringState.startPeriod
    );
  }, [recurringState.financialYear, recurringState.startPeriod, recurringState.frequency, open]);

  const initializeStateFromDetails = (
    currentFinancialYear: string,
    getSelectedFY: () => string,
    selectedPeriod: string
  ) => {
    if(!recurringState.dates.length){
      const FY = getSelectedFY() ? getSelectedFY() : currentFinancialYear;
      const SP = recurringState?.frequency === "monthly" ? months[0] : recurringState?.frequency === "quaterly" ? quarters[0] : recurringState?.frequency === "half_yearly" ? halfYears[0] : "";
      const periods = handleStartPeriodChangeXx(SP, FY, recurringState.frequency, recurringState.dates, recurringState.financialYear);
  
      const adjustedDates = periods.map((date, index) => ({
        startDate: date.startDate,
        dueDate: date.dueDate,
        period: date.period,
        expectedCompletionDate: null,
      }));
  
      setStartPeriods(getPeriodsBasedOnFrequency(recurringState.frequency));
  
      setRecurringState({
        ...recurringState,
        financialYear: FY,
        frequency: recurringState.frequency,
        startPeriod: SP,
        dates: adjustedDates,
      });  
    }
  };

  function getPeriodsBasedOnFrequency(frequency: RecurringFrequency): string[] {
    let result: string[] = [];

    if (frequency === RecurringFrequency.MONTHLY) {
      result = months;
    }

    if (frequency === RecurringFrequency.QUARTERLY) {
      result = quarters;
    }

    if (frequency === RecurringFrequency.HALF_YEARLY) {
      result = halfYears;
    }

    return result;
  }

  const handleDatePickerClose = (index: number, type: DateTypes) => {
    let dateIndex = index * 3;

    switch (type) {
      case DateTypes.STATUTORY_DATE:
        dateIndex += 1;
        break;
      case DateTypes.EXPECTED_DATE:
        dateIndex += 2;
        break;
    }

    if (dateIndex < openDatePicker.length) {
      const newOpenDatePicker = [...openDatePicker];
      newOpenDatePicker[dateIndex] = false;
      setOpenDatePicker(newOpenDatePicker);
    }
  };

  const handleDateSelect = (index: number, type: DateTypes) => {
    let dateIndex = index * 3;

    switch (type) {
      case DateTypes.STATUTORY_DATE:
        dateIndex += 1;
        break;
      case DateTypes.EXPECTED_DATE:
        dateIndex += 2;
        break;
    }

    const newForFocus = [...forFocus];
    newForFocus[dateIndex] = false;
    setForFocus(newForFocus);
  };

  const handleDatePickerOpen = (index: number, type: DateTypes) => {
    let dateIndex: number | undefined;
    switch (type) {
      case DateTypes.START_DATE:
        dateIndex = index * 3;
        break;
      case DateTypes.STATUTORY_DATE:
        dateIndex = index * 3 + 1;
        break;
      case DateTypes.EXPECTED_DATE:
        dateIndex = index * 3 + 2;
        break;
      default:
        dateIndex = -1;
    }

    if (dateIndex >= 0 && dateIndex < openDatePicker.length && forFocus[dateIndex]) {
      const newOpenDatePicker = [...openDatePicker];
      newOpenDatePicker[dateIndex] = true;

      const newForFocus = Array(openDatePicker.length).fill(false);
      newForFocus[dateIndex] = true;

      setOpenDatePicker(newOpenDatePicker);
      setForFocus(newForFocus);
    }
  };

  const setDatePickerOpenClick = (index: number, type: DateTypes) => {
    let dateIndex: number | undefined;

    switch (type) {
      case DateTypes.START_DATE:
        dateIndex = index * 3;
        break;
      case DateTypes.STATUTORY_DATE:
        dateIndex = index * 3 + 1;
        break;
      case DateTypes.EXPECTED_DATE:
        dateIndex = index * 3 + 2;
        break;
      default:
        dateIndex = -1;
    }

    if (dateIndex >= 0) {
      const newDatePickerOpen = [...openDatePicker];
      newDatePickerOpen[dateIndex] = true;
      setOpenDatePicker(newDatePickerOpen);
    }
  };

  const getIndex = (index: number, type: string) => {
    let newDateIndex: number | undefined;

    switch (type) {
      case DateTypes.START_DATE:
        newDateIndex = index * 3;
        break;
      case DateTypes.STATUTORY_DATE:
        newDateIndex = index * 3 + 1;
        break;
      case DateTypes.EXPECTED_DATE:
        newDateIndex = index * 3 + 2;
        break;
      default:
        return false;
    }

    if (newDateIndex >= 0 && newDateIndex < openDatePicker.length) {
      return openDatePicker[newDateIndex];
    }

    return false;
  };

  const boxRef = useRef<HTMLDivElement>(null);

  const handleFreqChange = (frequency: any) => {
    let result: string[] = [];

    if (frequency === RecurringFrequency.MONTHLY) {
      result = months;
    }

    if (frequency === RecurringFrequency.QUARTERLY) {
      result = quarters;
    }

    if (frequency === RecurringFrequency.HALF_YEARLY) {
      result = halfYears;
    }

    if (frequency === RecurringFrequency.YEARLY) {
      setStartPeriods([]);
      setRecurringState({
        ...recurringState,
        frequency,
        startPeriod: "",
        dates: [{ startDate: null, dueDate: null, expectedCompletionDate: null, period: "Yearly" }],
      });

      return;
    }

    if (frequency === RecurringFrequency.CUSTOM) {
      setStartPeriods([]);
      setRecurringState({
        ...recurringState,
        frequency,
        startPeriod: "",
        dates: [{ startDate: null, dueDate: null, expectedCompletionDate: null, period: "" }],
      });
      return;
    }

    setStartPeriods(result);
    setRecurringState({
      ...recurringState,
      frequency,
      startPeriod: "",
      dates: [],
    });
  };

  const handleStartPeriodChangeXx = (v: string, FY: string, RF: string, dates, baseFY) => {
    let periods: any = [];
    if (RF === "quarterly") {
      startPeriods.map(v => {
        const year = v === "Q1 (April - June)" ||
        v === "Q2 (July - September)" ||
        v === "Q3 (October - December)" ? recurringState.financialYear.substring(0, 4) : recurringState.financialYear.substring(5, 9);
        let period = v;
        period = `${v} ${year}`;
        periods.push({
          period,
          startDate: null,
          dueDate: null,
        });
      });
    } else if (recurringState.frequency === "monthly") {
      startPeriods.map((v) => {
        let period = v;
        const year = v === "April" ||
        v === "May" ||
        v === "June" ||
        v === "July" ||
        v === "August" ||
        v === "September" ||
        v === "October" ||
        v === "November" ||
        v === "December" ? recurringState.financialYear.substring(0, 4) : recurringState.financialYear.substring(5, 9);
        period = `${v} ${year}`;
        periods.push({
          period,
          startDate: null,
          dueDate: null,
        });
      });

    } else if (RF === "half_yearly") {
      startPeriods.map((v) => {
        let period = v;
        const year = v === "H1 (April - September)" ? recurringState.financialYear.substring(0, 4) : recurringState.financialYear.substring(5, 9);

        period = `${v} ${year}`;
        periods.push({
          period,
          startDate: null,
          dueDate: null,
        });
      });
    } else if (RF === 'yearly') {
      const frequency = RF;
      const period = `Yearly ${FY}`;
      periods.push({
        period,
        startDate: null,
        dueDate: null,
      });
    }

    return periods;
  };

  const handleSave = () => {
    if (!recurringState.financialYear) {
      snack.error("Please select a financial year");
      return;
    }

    if (!recurringState.frequency) {
      snack.error("Please select a frequency");
      return;
    }

    let emptyDates;

    if (recurringState.frequency !== RecurringFrequency.YEARLY) {
      emptyDates = recurringState.dates.some((v) => !v.startDate || !v.dueDate || !v.period);
    } else {
      emptyDates = recurringState.dates.some((v) => !v.startDate || !v.dueDate);
    }

    if (emptyDates) {
      snack.error("Please select a start, due date, and period for each row");
      return;
    }
    setSaveState({ ...saveState, recurringDetails: recurringState });
    setOpen(false);
  };

  const handleStartDateChange = (v, index) => {
    if (v) {
      if (recurringState.frequency === "monthly") {
        if (index === 0) {
          const newDates = recurringState.dates.map((item, index) => {
            let dates = [...recurringState.dates];
            dates[index].startDate = moment(v).add(index, "month").format("YYYY-MM-DD");
            setRecurringState({ ...recurringState, dates });
          });
        } else {
          let dates = [...recurringState.dates];
          dates[index].startDate = moment(v).format("YYYY-MM-DD");
          setRecurringState({ ...recurringState, dates });
        }
      } else if (recurringState.frequency === "quarterly") {
        if (index === 0) {
          let number = 0;
          const newDates = recurringState.dates.map((item, index) => {
            let dates = [...recurringState.dates];
            dates[index].startDate = moment(v).add(number, "month").format("YYYY-MM-DD");
            setRecurringState({ ...recurringState, dates });
            number += 3;
          });
        } else {
          let dates = [...recurringState.dates];
          dates[index].startDate = moment(v).format("YYYY-MM-DD");
          setRecurringState({ ...recurringState, dates });
        }
      } else if (recurringState.frequency === "half_yearly") {
        if (index === 0) {
          let number = 0;
          const newDates = recurringState.dates.map((item, index) => {
            let dates = [...recurringState.dates];
            dates[index].startDate = moment(v).add(number, "month").format("YYYY-MM-DD");
            setRecurringState({ ...recurringState, dates });
            number += 6;
          });
        } else {
          let dates = [...recurringState.dates];
          dates[index].startDate = moment(v).format("YYYY-MM-DD");
          setRecurringState({ ...recurringState, dates });
        }
      } else {
        let dates = [...recurringState.dates];
        dates[index].startDate = moment(v).format("YYYY-MM-DD");
        setRecurringState({ ...recurringState, dates });
      }
    }
  };

  const handleDueDateChange = (v, index) => {
    if (v) {
      if (recurringState.frequency === "monthly") {
        if (index === 0) {
          const newDates = recurringState.dates.map((item, index) => {
            let dates = [...recurringState.dates];
            dates[index].dueDate = moment(v).add(index, "month").format("YYYY-MM-DD");
            setRecurringState({ ...recurringState, dates });
          });
        } else {
          let dates = [...recurringState.dates];
          dates[index].dueDate = moment(v).format("YYYY-MM-DD");
          setRecurringState({ ...recurringState, dates });
        }
      } else if (recurringState.frequency === "quarterly") {
        if (index === 0) {
          let number = 0;
          const newDates = recurringState.dates.map((item, index) => {
            let dates = [...recurringState.dates];
            dates[index].dueDate = moment(v).add(number, "month").format("YYYY-MM-DD");
            setRecurringState({ ...recurringState, dates });
            number += 3;
          });
        } else {
          let dates = [...recurringState.dates];
          dates[index].dueDate = moment(v).format("YYYY-MM-DD");
          setRecurringState({ ...recurringState, dates });
        }
      } else if (recurringState.frequency === "half_yearly") {
        if (index === 0) {
          let number = 0;
          const newDates = recurringState.dates.map((item, index) => {
            let dates = [...recurringState.dates];
            dates[index].dueDate = moment(v).add(number, "month").format("YYYY-MM-DD");
            setRecurringState({ ...recurringState, dates });
            number += 6;
          });
        } else {
          let dates = [...recurringState.dates];
          dates[index].dueDate = moment(v).format("YYYY-MM-DD");
          setRecurringState({ ...recurringState, dates });
        }
      } else {
        let dates = [...recurringState.dates];
        dates[index].dueDate = moment(v).format("YYYY-MM-DD");
        setRecurringState({ ...recurringState, dates });
      }
    }
  };

  const handleChangeExpectedDate = (v, index) => {
    if (v) {
      if (recurringState.frequency === "monthly") {
        if (index === 0) {
          const newDates = recurringState.dates.map((item, index) => {
            let dates = [...recurringState.dates];
            dates[index].expectedCompletionDate = moment(v)
              .add(index, "month")
              .format("YYYY-MM-DD");
              setRecurringState({ ...recurringState, dates });
          });
        } else {
          let dates = [...recurringState.dates];
          dates[index].expectedCompletionDate = moment(v).format("YYYY-MM-DD");
          setRecurringState({ ...recurringState, dates });
        }
      } else if (recurringState.frequency === "quarterly") {
        if (index === 0) {
          let number = 0;
          const newDates = recurringState.dates.map((item, index) => {
            let dates = [...recurringState.dates];
            dates[index].expectedCompletionDate = moment(v)
              .add(number, "month")
              .format("YYYY-MM-DD");
              setRecurringState({ ...recurringState, dates });
            number += 3;
          });
        } else {
          let dates = [...recurringState.dates];
          dates[index].expectedCompletionDate = moment(v).format("YYYY-MM-DD");
          setRecurringState({ ...recurringState, dates });
        }
      } else if (recurringState.frequency === "half_yearly") {
        if (index === 0) {
          let number = 0;
          const newDates = recurringState.dates.map((item, index) => {
            let dates = [...recurringState.dates];
            dates[index].expectedCompletionDate = moment(v)
              .add(number, "month")
              .format("YYYY-MM-DD");
              setRecurringState({ ...recurringState, dates });
            number += 6;
          });
        } else {
          let dates = [...recurringState.dates];
          dates[index].expectedCompletionDate = moment(v).format("YYYY-MM-DD");
          setRecurringState({ ...recurringState, dates });
        }
      } else {
        let dates = [...recurringState.dates];
        dates[index].expectedCompletionDate = moment(v).format("YYYY-MM-DD");
        setRecurringState({ ...recurringState, dates });
      }
    }
  };

  return (
    <DialogWrapper title="Recurring Frequency" open={open} setOpen={setOpen}>
      <TextField
        label="Financial Year"
        select
        disabled
        size="small"
        fullWidth
        value={recurringState.financialYear}
        onChange={(e) => {
          setRecurringState({ ...recurringState, financialYear: e.target.value, startPeriod: "", dates: [] });
        }}
      >
        {getFinancialYears().map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </TextField>
      <Box mt={2}>
        <TextField
          label="Frequency"
          select
          size="small"
          fullWidth
          disabled
          value={recurringState.frequency}
          onChange={(e) => handleFreqChange(e.target.value)}
        >
          {Object.values(RecurringFrequency).map((item) => (
            <MenuItem key={item} value={item}>
              {getTitle(item)}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      {recurringState.dates.length > 0 && (
        <Box
          mt={2}
          ref={boxRef}
          bgcolor="#F7F7F7"
          px={1}
          py={1}
          sx={{ maxHeight: 300, overflow: "auto" }}
        >
          <Box display="flex" mb={1}>
            <Typography variant="body2">Select Dates</Typography>
          </Box>
          {recurringState.dates.map((item: any, index: number) => (
            <Box mb={2} key={index}>
              <Typography variant="caption" color="rgba(0,0,0,0.6)">
                {item.period}
              </Typography>
              <Box display="flex" gap={1} mt="8px">
                <DesktopDatePicker
                  label="Start Date*"
                  mask="__-__-____"
                  inputFormat="dd-MM-yyyy"
                  open={getIndex(index, DateTypes.START_DATE)}
                  value={item.startDate}
                  onClose={() => handleDatePickerClose(index, DateTypes.START_DATE)}
                  onChange={(v) => {
                    handleStartDateChange(v, index);
                    handleDateSelect(index, DateTypes.START_DATE);
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      size="small"
                      onKeyDown={handleKeyDown}
                      {...params}
                      onFocus={() => handleDatePickerOpen(index, DateTypes.START_DATE)}
                      onClick={() => {
                        setDatePickerOpenClick(index, DateTypes.START_DATE);
                      }}
                      autoComplete="off"
                    />
                  )}
                />
                <DesktopDatePicker
                  label="Statutory Due Date*"
                  inputFormat="dd-MM-yyyy"
                  mask="__-__-____"
                  value={item.dueDate}
                  onChange={(v) => {
                    handleDueDateChange(v, index);
                    handleDateSelect(index, DateTypes.STATUTORY_DATE);
                  }}
                  open={getIndex(index, DateTypes.STATUTORY_DATE)}
                  onClose={() => handleDatePickerClose(index, DateTypes.STATUTORY_DATE)}
                  minDate={new Date(2000, 0, 1)}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      size="small"
                      onKeyDown={handleKeyDown}
                      onFocus={() => handleDatePickerOpen(index, DateTypes.STATUTORY_DATE)}
                      onClick={() => {
                        setDatePickerOpenClick(index, DateTypes.STATUTORY_DATE);
                      }}
                      autoComplete="off"
                      {...params}
                    />
                  )}
                />
                <DesktopDatePicker
                  label="Expected Completion Date "
                  inputFormat="dd-MM-yyyy"
                  mask="__-__-____"
                  value={item.expectedCompletionDate}
                  minDate={moment(recurringState.dates[index].startDate).toDate()}
                  onChange={(v) => {
                    handleChangeExpectedDate(v, index);
                    handleDateSelect(index, DateTypes.EXPECTED_DATE);
                  }}
                  open={getIndex(index, DateTypes.EXPECTED_DATE)}
                  onClose={() => handleDatePickerClose(index, DateTypes.EXPECTED_DATE)}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      size="small"
                      onFocus={() => handleDatePickerOpen(index, DateTypes.EXPECTED_DATE)}
                      onClick={() => {
                        setDatePickerOpenClick(index, DateTypes.EXPECTED_DATE);
                      }}
                      {...params}
                      onKeyDown={handleKeyDown}
                      autoComplete="off"
                    />
                  )}
                />
                {recurringState.frequency === RecurringFrequency.CUSTOM && (
                  <TextField
                    fullWidth
                    size="small"
                    label="Period"
                    onChange={(e) => {
                      let dates = [...recurringState.dates];
                      dates[index].period = e.target.value;
                      setRecurringState({ ...recurringState, dates });
                    }}
                    value={item.period}
                  />
                )}
                {recurringState.frequency === RecurringFrequency.CUSTOM && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      let dates = [...recurringState.dates];
                      dates.splice(index, 1);
                      setRecurringState({ ...recurringState, dates });
                    }}
                  >
                    <Delete fontSize="small" color="secondary" />
                  </IconButton>
                )}
              </Box>
            </Box>
          ))}
          {recurringState.frequency === RecurringFrequency.CUSTOM && (
            <Box mt={1} textAlign="right">
              <Button
                color="secondary"
                onClick={() => {
                  setRecurringState({
                    ...recurringState,
                    dates: [
                      ...recurringState.dates,
                      { startDate: null, dueDate: null, expectedCompletionDate: null, period: "" },
                    ],
                  });
                }}
              >
                + Add Date
              </Button>
            </Box>
          )}
        </Box>
      )}
      <Box mt={2} textAlign="center">
        <Button onClick={handleSave} variant="contained" color="secondary" size="large">
          Save
        </Button>
      </Box>
    </DialogWrapper>
  );
}

export default RecurringFrequencyDetails;
