import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Switch, Typography } from "@mui/material"
import { getTaskPreferences, updateTaskPreferences } from "api/services/invoice.preferencess";
import BottomBar from "components/BottomBar";
import { snack } from "components/toast";
import { UserProfileContext } from "context/UserProfile";
import { useContext, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { handleError } from "utils/handleError";

const LogHourPreferences = () => {
    const queryClient = useQueryClient();
    const { data: uData } = useContext(UserProfileContext);
    const [originalState, setOriginalState] = useState<any>({ loghours: false, logHoursDate: 1, previousDayDate: 1, isPrevious: false, isDescription: false });
    const [task, setTask] = useState<any>({ loghours: false, logHoursDate: 1, previousDayDate: 1, isPrevious: false, isDescription: false });

    const { isLoading: queryLoading } = useQuery(["task-preferenecs", uData?.id], getTaskPreferences, {
        onSuccess: (res: any) => {
            // setTask(res?.data?.taskPreferences);

            if (res?.data?.taskPreferences) {
                setTask((prev) => ({
                    ...prev,
                    ...res?.data?.taskPreferences
                }));
                setOriginalState((prev) => ({
                    ...prev,
                    ...res?.data?.taskPreferences
                }))
            }
        }
    })

    const { data, mutate, isLoading } = useMutation(updateTaskPreferences, {
        onSuccess: () => {
            snack.success("Task Preferences Updated!");
            queryClient.invalidateQueries("task-preferenecs");
        },
        onError: (err: any) => {
            snack.error(handleError(err));
        },
    });

    const handleUpdatePermissons = async () => {
        const data = { ...task };
        mutate({ data: { taskPreferences: data } });

    };

    return (
        <Box m={2}>
            <>
                <Grid container mt={5}>
                    <Grid item xs={7}>
                        <Typography variant="body1">Is it mandatory for employees in the organization to add descriptions for every timesheet entry?</Typography>

                    </Grid>
                    <Grid item xs={3}>
                        <Box display="flex" alignItems="center">
                            <Switch
                                onChange={() => setTask({ ...task, isDescription: !task?.isDescription })}
                                checked={task?.isDescription}
                                sx={{ marginRight: "25px" }}
                            />
                            <Typography>{task?.isDescription ? "On" : "Off"}</Typography>
                        </Box>
                    </Grid>
                </Grid>


            </>

            <Grid container mt={5}>
                <Grid item xs={7}>
                    <Typography variant="body1" > Should employees in the organisation be able to add log hours for the Previous Day?</Typography>

                </Grid>
                <Grid item xs={3}>
                    <Box display="flex" alignItems="center">
                        <Switch
                            onChange={() => setTask({ ...task, isPrevious: !task?.isPrevious })}
                            checked={task?.isPrevious}
                            sx={{ marginRight: "25px" }}
                        />
                        <Typography>{task?.isPrevious ? "On" : "Off"}</Typography>
                    </Box>
                </Grid>
            </Grid>


            <Box mt={2}>
                {task?.isPrevious && (
                    <>
                        <Grid container mt={3} ml={5}>
                            <Grid item xs={7}>
                                <Typography >How many days should employees be allowed to add log hours?</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl >
                                    <InputLabel>Duration</InputLabel>
                                    <Select
                                        size="small"
                                        placeholder="previousDayPrefenences"
                                        labelId="Previous Days Prefenences"
                                        id="previousDayprefernces"
                                        value={task?.previousDayDate || null}
                                        onChange={(value) => {
                                            setTask({ ...task, previousDayDate: value.target.value })
                                        }}
                                        label="Previous Days Preferences"
                                    >
                                        <MenuItem key={"one"} value={1}>
                                            One Day
                                        </MenuItem>
                                        <MenuItem key={"week"} value={7}>
                                            One Week
                                        </MenuItem>
                                        <MenuItem key={"15"} value={15}>
                                            15 Days
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                    </>
                )}
            </Box>

            <Grid item xs={12} mt={10}>
                <Typography variant="h6" color="secondary" display="inline" fontSize={18}>Note:</Typography>
                <Typography variant="inherit" color="#000000" fontWeight={"700"} mt={0.5} fontSize={13}>
                    Employees cannot add log hours for Deleted / Terminated Task
                </Typography>
                <Typography variant="inherit" color="#000000" fontWeight={"700"} mt={0.5} fontSize={13}>
                    They can add log hours for the task which are created and in the status of Todo, In Progress, On Hold, Under Review
                </Typography>
                <Typography variant="inherit" color="#000000" fontWeight={"700"} mt={0.5} fontSize={13}>
                    Where as for the Completed Task for the purpose of Timesheet, it will be Archived only after the time set within the preference, which won't consider the preference set for the Dashboard & Taskboard
                </Typography>
            </Grid>
            <BottomBar
                isLoading={isLoading}
                data={originalState}
                state={task}
                onCancel={() => setTask(originalState)}
                onUpdate={handleUpdatePermissons}
            />
        </Box>
    )
}

export default LogHourPreferences