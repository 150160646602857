import { Box, List, ListItem, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import DialogWrapper from "components/DialogWrapper";

const BulkRecurringInfo = (props) => {
    const { open, setOpen } = props;

    return (
        <DialogWrapper
            title="Important Information on Bulk Update Dates in Recurring Profiles"
            open={open}
            setOpen={setOpen}
            width="lg"
        >
            <Box sx={{ padding: 2, backgroundColor: '#f9f9f9', borderRadius: 2 }}>
                <Typography variant="body1" gutterBottom mb={2}>
                    To update the dates (Start Date, Statutory Date, and Expected Completion Date) for all the upcoming tasks, it is necessary to define the pattern for task creation based on the selected frequency (e.g., monthly, quarterly, half-annually, or annually) within a financial year.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <span style={{ fontWeight: "700" }}>For example</span>, Consider a task frequency of quarterly for an FY. In this case, there will be four compliance periods as follows:
                </Typography>
                <List>
                    <ListItemText>Quarter 1: April - June
                    </ListItemText>
                    <ListItemText>Quarter 2: July - September
                    </ListItemText>
                    <ListItemText>Quarter 3: October - December
                    </ListItemText>
                    <ListItemText>Quarter 4: January - March
                    </ListItemText>
                </List>
                <Typography>When updating Start, Statutory, or Expected Completion Dates, you can define a pattern for a specific financial year. ATOM analyzes your date selections to understand the pattern (e.g., current vs. next FY, same vs. subsequent period). Based on this, ATOM automatically updates the dates of all future tasks that haven't been created yet, following the identified pattern. This pattern persists across all subsequent periods within the current financial year and applies to all future financial years unless the user explicitly changes it. Importantly, this feature does not alter the dates of any tasks that have already been created.
                </Typography>
                <Typography variant="h6" color="secondary" sx={{mt:1}}>Note:</Typography>
                <Typography sx={{ml:2}}>
                    1. Bulk date updates necessitate the selection of Service Name, Frequency, and Assignee within the designated Filters
                </Typography>
                <Typography sx={{ml:2}}>2. To ensure accurate scheduling, you must define specific dates (for Start and Statutory Due Date) for every period within a given Financial Year in a designated frequency. This comprehensive approach is essential for maintaining a consistent and reliable schedule.
                </Typography>
            </Box>
        </DialogWrapper>
    );
}

export default BulkRecurringInfo