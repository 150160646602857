import { Box, Breadcrumbs, Paper, Typography } from "@mui/material";
import { getBulkRecurringProfile, getBulkTasks, recurringProfileBulkUpdate, taskBulkUpdate } from "api/services/tasks/tasks";
import { snack } from "components/toast";
import useTitle from "hooks/useTitle";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { handleError } from "utils/handleError";
import ErrorsModal from "views/bulk-edit/RecurringProfileBulkEdit/ErrorsModal";
import Filters from "views/bulk-edit/RecurringProfileBulkEdit/Filters";
import HandleSelectedField from "views/bulk-edit/RecurringProfileBulkEdit/HandleSelectedField";
import Report from "views/bulk-edit/RecurringProfileBulkEdit/Report";

export interface IState {
  client: any;
  members: any;
  taskLeader: any;
  approvalUser: any;
  labels: any;
  priority: Array<any>;
  category: any;
  subCategory: any;
  selectedField: "" | string;
  existingValue: any;
  newValue: any;
  newMembers: any[];
  newTaskLeaders: Array<any>;
  newLabels: Array<any>;
  service: any;
  showMore: Boolean;
  approvalProcedures: any;
  udinTask: any,
  frequency: any,
  recurringDetails: any,
}


function RecurringProfileBulkEdit() {
  useTitle("Recurring Profile Bulk Activity");

  const [data, setData] = useState(null);
  const [state, setState] = useState<IState>({
    client: null,
    members: null,
    taskLeader: null,
    approvalUser: null,
    labels: null,
    priority: [],
    category: null,
    subCategory: null,
    selectedField: "",
    existingValue: null,
    newValue: null,
    newTaskLeaders: [],
    newMembers: [],
    newLabels: [],
    service: null,
    showMore: false,
    approvalProcedures: null,
    udinTask: null,
    frequency: null,
    recurringDetails: null,
  });

  const initialState: IState = {
    client: null,
    members: null,
    taskLeader: null,
    approvalUser: null,
    labels: null,
    priority: [],
    category: null,
    subCategory: null,
    selectedField: "",
    existingValue: null,
    newValue: null,
    newTaskLeaders: [],
    newMembers: [],
    newLabels: [],
    service: null,
    showMore: false,
    approvalProcedures: [],
    udinTask: null,
    frequency: null,
    recurringDetails: null
  }
  const [errorsList, setErrorsList] = useState<any[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [selected, setSelected] = useState<any[]>([]);
  const [sucessCount, setSucessCount] = useState<number>(0);
  const [failCount, setFailCount] = useState<number>(0);

  const { mutate, isLoading, isError } = useMutation(getBulkRecurringProfile, {
    onSuccess: (res: any) => {
      setData(res?.data);
    },
    onError: (err: any) => {
      snack.error(handleError(err));
    },
  });
  const handleSubmit = () => {
    if(!state.members) return snack.error("Please Select Assignee ");
    mutate({
      ...state,
      client: state.client?.id,
      clientType: state.client?.type,
      members: state.members?.id,
      approvalUser: state?.approvalUser?.id,
      taskLeader: state.taskLeader === "NA" ? "NA" : state.taskLeader?.id,
      category: state.category?.id,
      subCategory: state.subCategory?.id,
      selectedField: state.selectedField,
      priority: state.priority?.map((item) => item.value),
      service: state.service?.id,
      offset,
      limit,
    });
  };

  useEffect(() => {
    if(state.members){
      handleSubmit();
    }
  },[limit,offset])

  const { mutate: bulkUpdateMutation, isLoading: bulkUpdationLoading } = useMutation(recurringProfileBulkUpdate, {
    onSuccess: (res: any) => {
      setErrorsList(res?.data?.errorsListArray)
      setFailCount(res?.data?.errorsListArray?.length)
      if (res.data?.validTaskIdsArray?.length > 0) {
        setData(null);
        snack.success(`${res.data?.validTaskIdsArray?.length} Task(s) Updated`);
        setSucessCount(res.data?.validTaskIdsArray?.length);
      } else if (res.data?.validTaskIdsArray?.length === 0) {
        snack.error('No Valid Tasks To update')
      }
      setSelected([])
    },
    onError: (err: any) => {
      snack.error(handleError(err));
      setSelected([])
    },
  });

  const handleViewMore = () => {
    setState((prevState) => ({
      ...prevState,
      showMore: !prevState.showMore,
    }));
  };

  const handlePageChange = (page: number) => {
    setOffset(page * limit);
  };

  const handleLimitChange = (newLimit: number) => {
    setLimit(newLimit);
    setOffset(0);
  };

  const onBulkUpdate = (selected) => {
    bulkUpdateMutation({
      ids: selected.map((c: any) => c.id),
      selectedField: state.selectedField ? state.selectedField : null,
      newMembers: state.newMembers?.map((user) => user),
      newValue: state?.newValue ? state.newValue : null,
      newTaskLeaders: state.newTaskLeaders?.map((leader) => leader),
      newLabels: state.newLabels?.map((label) => label),
      udinTask: state.udinTask ? true : false,
      recurringDetails: state.recurringDetails ? state?.recurringDetails : null,
    });
  }

  const clearFilters = () => {
    setState(initialState);
    setData(null);
  }

  return (
    <Box p={2} >
      <Filters state={state} isloading={isLoading} setState={setState} onSubmit={handleSubmit} clearFilters={clearFilters} handleViewMore={handleViewMore} setData={setData} initialState={initialState} />
      <HandleSelectedField state={state} isloading={isLoading} setState={setState} onSubmit={handleSubmit} />
      <Report isLoading={isLoading} isError={isError} state={state} setState={setState} data={data} onBulkUpdate={onBulkUpdate} limit={limit} offset={offset} handleLimitChange={handleLimitChange} handlePageChange={handlePageChange} selected={selected} setSelected={setSelected} bulkUpdationLoading={bulkUpdationLoading} handleSubmit={handleSubmit} />
      <ErrorsModal errorsList={errorsList} onClose={() => setErrorsList([])} sucessCount={sucessCount} failCount={failCount} />
    </Box>
  );
}

export default RecurringProfileBulkEdit;
