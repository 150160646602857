import { DatePicker } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { getUsersForReport } from "api/services/users";
import Loader from "components/Loader";
import { FromToDates, PriorityEnum, TaskFields, TaskStatusReports } from "data/constants";
import { IState } from "pages/settings/bulk-activity/recurring-profile-bulk-activity";
import { useState } from "react";
import { useQuery } from "react-query";
import { ResType } from "types";
import { getTitle } from "utils";
import { usePermissions } from "context/PermissionsProvider";
import { getLabels } from "api/services/labels";
import SelectApprovalHierarchy from "views/tasks/board/CreateTask/SelectApprovalHierarchy";
import { StyledSelectBox, StyledSelectedBox } from "views/tasks/board/CreateTask/styles";
import { Edit } from "@mui/icons-material";
import RecurringFrequencyDetails from "./RecurringFrequencyDetails";

interface Props {
  state: IState;
  setState: (state: IState) => void;
  onSubmit: () => void;
  isloading: boolean;
}

function HandleSelectedField({ state, isloading, setState, onSubmit }: Props) {
  const { data, isLoading }: ResType = useQuery(["users"], getUsersForReport);
  const { data: labels, isLoading: labelsLoading }: ResType = useQuery(
    "labels",
    getLabels,
  );
  const [openSelectAppHier, setOpenSelectAppHier] = useState(false);
  const [recurringOpen, setRecurringOpen] = useState<Boolean>(false);
  const [recurringState, setRecurringState] = useState<any>({ dates: [], financialYear: "", frequency: state?.frequency });

  if (isLoading) return <Loader />;

  return (
    <Paper sx={{ pl: 2, pr: 2, pb: 2, backgroundColor: "#EEF5FF", width: "100%" }}>
      <Grid container spacing={2}>

        {/* //PRIORITY */}
        {state.selectedField === "priority" && (
          <>
            <Grid item xs={4}>
              <TextField
                select
                label="New Task Priority"
                fullWidth
                size="small"
                name="newValue"
                value={state.newValue}
                onChange={(e: any) => {
                  setState({ ...state, newValue: e.target.value });
                }}
              >
                {Object.values(PriorityEnum).map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {getTitle(item)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </>
        )}

        {/* //BILLING TYPE */}
        {state.selectedField === "billing_type" && (
          <Grid item xs={4}>
            <TextField
              select
              label="New Billing Type"
              fullWidth
              size="small"
              name="newValue"
              value={state.newValue}
              onChange={(e: any) => {
                setState({ ...state, newValue: e.target.value });
              }}
            >
              <MenuItem value="BILLABLE">Billable</MenuItem>
              <MenuItem value="NON_BILLABLE">Non-Billable</MenuItem>
            </TextField>
          </Grid>
        )}

        {/* //Dates */}
        {state.selectedField === "update_dates" && (
          <Grid item xs={12} sx={{ mt: 2, mb: 0 }}>
            <Box mt={1}>
              {state?.recurringDetails ? (
                <StyledSelectedBox sx={{ mt: "2px" }}>
                  <Box display="flex" gap={1} alignItems="center">
                    <Typography variant="subtitle2">
                      Frequency
                    </Typography>
                  </Box>
                  <IconButton onClick={() => {
                    setRecurringState({ ...recurringState, frequency: state?.frequency })
                    setRecurringOpen(true)
                  }} size="small">
                    <Edit fontSize="small" />
                  </IconButton>
                </StyledSelectedBox>
              ) : (
                <StyledSelectBox sx={{ mt: "2px" }} onClick={() => {
                  setRecurringState({ ...recurringState, frequency: state?.frequency })
                  setRecurringOpen(true)
                }}>
                  <Typography variant="body1" color="rgba(0,0,0,0.5)">
                    + Add Recurring Frequency
                  </Typography>
                </StyledSelectBox>
              )}
            </Box>
          </Grid>
        )}

        {/* //LABELS */}
        {state.selectedField === "add_label" && (
          <Grid item xs={6}>
            <FormControl fullWidth size="small" sx={{ flex: 1, maxWidth: "41vw" }}>
              <InputLabel id="demo-multiple-checkbox-label">Add New Label</InputLabel>
              <Select
                multiple
                value={state.newLabels}
                input={<OutlinedInput label="New Label" />}
                renderValue={(selected) => {
                  return labels?.data?.[0]
                    ?.filter((item: any) => selected.includes(item.id))
                    .map((item: any) => item.name)
                    .join(", ");
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: "300px",
                    },
                  },
                }}
                sx={{ maxWidth: "41vw" }}
              >
                <Box display={"flex"} alignItems={"center"} m={1}>
                  <Checkbox
                    checked={labels?.data?.[0]?.length === state.newLabels.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setState({
                          ...state,
                          newLabels: labels?.data?.[0]?.map((item: any) => item?.id),
                        });
                      } else {
                        setState({ ...state, newLabels: [] });
                      }
                    }}
                    sx={{ marginRight: "10px" }}
                  />
                  <Typography> Select All </Typography>
                </Box>
                {labels?.data?.[0]?.map((item: any) => (
                  <Box display={"flex"} alignItems={"center"} m={1}>
                    <Checkbox
                      checked={state.newLabels.indexOf(item?.id) > -1}
                      onChange={(e) => {
                        if (e.target.checked) {
                          if (state.newLabels.includes(item.id)) {
                            const userList = state.newLabels.filter(
                              (eachitem) => eachitem !== item.id
                            );
                            setState({
                              ...state,
                              newLabels: [...userList],
                            });
                          } else {
                            setState({
                              ...state,
                              newLabels: [...state.newLabels, item.id],
                            });
                          }
                        } else {
                          const userList = state.newLabels.filter(
                            (eachitem) => eachitem !== item.id
                          );
                          setState({
                            ...state,
                            newLabels: [...userList],
                          });
                        }
                      }}
                      sx={{ marginRight: "10px" }}
                    />
                    <Typography>{item?.name}</Typography>
                  </Box>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {state.selectedField === "update_label" && (
          <Grid item xs={6}>
            <FormControl fullWidth size="small" sx={{ flex: 1, maxWidth: "41vw" }}>
              <InputLabel id="demo-multiple-checkbox-label">Update Labels</InputLabel>
              <Select
                multiple
                value={state.newLabels}
                input={<OutlinedInput label="New Labels" />}
                renderValue={(selected) => {
                  return labels?.data?.[0]
                    ?.filter((item: any) => selected.includes(item.id))
                    .map((item: any) => item.name)
                    .join(", ");
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: "300px",
                    },
                  },
                }}
                sx={{ maxWidth: "41vw" }}
              >
                <Box display={"flex"} alignItems={"center"} m={1}>
                  <Checkbox
                    checked={labels?.data?.[0]?.length === state.newLabels.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setState({
                          ...state,
                          newLabels: labels?.data?.[0]?.map((item: any) => item?.id),
                        });
                      } else {
                        setState({ ...state, newLabels: [] });
                      }
                    }}
                    sx={{ marginRight: "10px" }}
                  />
                  <Typography> Select All </Typography>
                </Box>
                {labels?.data?.[0]?.map((item: any) => (
                  <Box display={"flex"} alignItems={"center"} m={1}>
                    <Checkbox
                      checked={state.newLabels.indexOf(item?.id) > -1}
                      onChange={(e) => {
                        if (e.target.checked) {
                          if (state.newLabels.includes(item.id)) {
                            const userList = state.newLabels.filter(
                              (eachitem) => eachitem !== item.id
                            );
                            setState({
                              ...state,
                              newLabels: [...userList],
                            });
                          } else {
                            setState({
                              ...state,
                              newLabels: [...state.newLabels, item.id],
                            });
                          }
                        } else {
                          const userList = state.newLabels.filter(
                            (eachitem) => eachitem !== item.id
                          );
                          setState({
                            ...state,
                            newLabels: [...userList],
                          });
                        }
                      }}
                      sx={{ marginRight: "10px" }}
                    />
                    <Typography>{item?.name}</Typography>
                  </Box>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {state.selectedField === "remove_label" && (
          <Grid item xs={6}>
            <FormControl fullWidth size="small" sx={{ flex: 1, maxWidth: "41vw" }}>
              <InputLabel id="demo-multiple-checkbox-label">Remove Label</InputLabel>
              <Select
                multiple
                value={state.newLabels}
                input={<OutlinedInput label="New Labels" />}
                renderValue={(selected) => {
                  return labels?.data?.[0]
                    ?.filter((item: any) => selected.includes(item.id))
                    .map((item: any) => item.name)
                    .join(", ");
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: "300px",
                    },
                  },
                }}
                sx={{ maxWidth: "41vw" }}
              >
                <Box display={"flex"} alignItems={"center"} m={1}>
                  <Checkbox
                    checked={labels?.data?.[0]?.length === state.newLabels.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setState({
                          ...state,
                          newLabels: labels?.data?.[0]?.map((item: any) => item?.id),
                        });
                      } else {
                        setState({ ...state, newLabels: [] });
                      }
                    }}
                    sx={{ marginRight: "10px" }}
                  />
                  <Typography> Select All </Typography>
                </Box>
                {labels?.data?.[0]?.map((item: any) => (
                  <Box display={"flex"} alignItems={"center"} m={1}>
                    <Checkbox
                      checked={state.newLabels.indexOf(item?.id) > -1}
                      onChange={(e) => {
                        if (e.target.checked) {
                          if (state.newLabels.includes(item.id)) {
                            const userList = state.newLabels.filter(
                              (eachitem) => eachitem !== item.id
                            );
                            setState({
                              ...state,
                              newLabels: [...userList],
                            });
                          } else {
                            setState({
                              ...state,
                              newLabels: [...state.newLabels, item.id],
                            });
                          }
                        } else {
                          const userList = state.newLabels.filter(
                            (eachitem) => eachitem !== item.id
                          );
                          setState({
                            ...state,
                            newLabels: [...userList],
                          });
                        }
                      }}
                      sx={{ marginRight: "10px" }}
                    />
                    <Typography>{item?.name}</Typography>
                  </Box>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {/* //TASK LEADER */}
        {state.selectedField === "add_task_leader" && (
          <Grid item xs={6}>
            <FormControl fullWidth size="small" sx={{ flex: 1, maxWidth: "41vw" }}>
              <InputLabel id="demo-multiple-checkbox-label">Add New Task Leader</InputLabel>
              <Select
                multiple
                value={state.newTaskLeaders}
                input={<OutlinedInput label="New Task Leader" />}
                renderValue={(selected) => {
                  return data?.data
                    ?.filter((item: any) => selected.includes(item.id))
                    .map((item: any) => item.fullName)
                    .join(", ");
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: "300px",
                    },
                  },
                }}
                sx={{ maxWidth: "41vw" }}
              >
                <Box display={"flex"} alignItems={"center"} m={1}>
                  <Checkbox
                    checked={data?.data?.length === state.newTaskLeaders.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setState({
                          ...state,
                          newTaskLeaders: data?.data?.map((item: any) => item?.id),
                        });
                      } else {
                        setState({ ...state, newTaskLeaders: [] });
                      }
                    }}
                    sx={{ marginRight: "10px" }}
                  />
                  <Typography> Select All </Typography>
                </Box>
                {data?.data?.map((item: any) => (
                  <Box display={"flex"} alignItems={"center"} m={1}>
                    <Checkbox
                      checked={state.newTaskLeaders.indexOf(item?.id) > -1}
                      onChange={(e) => {
                        if (e.target.checked) {
                          if (state.newTaskLeaders.includes(item.id)) {
                            const userList = state.newTaskLeaders.filter(
                              (eachitem) => eachitem !== item.id
                            );
                            setState({
                              ...state,
                              newTaskLeaders: [...userList],
                            });
                          } else {
                            setState({
                              ...state,
                              newTaskLeaders: [...state.newTaskLeaders, item.id],
                            });
                          }
                        } else {
                          const userList = state.newTaskLeaders.filter(
                            (eachitem) => eachitem !== item.id
                          );
                          setState({
                            ...state,
                            newTaskLeaders: [...userList],
                          });
                        }
                      }}
                      sx={{ marginRight: "10px" }}
                    />
                    <Typography>{item?.fullName}</Typography>
                  </Box>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {state.selectedField === "update_task_leader" && (
          <Grid item xs={6}>
            <FormControl fullWidth size="small" sx={{ flex: 1, maxWidth: "41vw" }}>
              <InputLabel id="demo-multiple-checkbox-label">Update Task Leader</InputLabel>
              <Select
                multiple
                value={state.newTaskLeaders}
                input={<OutlinedInput label="New Task Leader" />}
                renderValue={(selected) => {
                  return data?.data
                    ?.filter((item: any) => selected.includes(item.id))
                    .map((item: any) => item.fullName)
                    .join(", ");
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: "300px",
                    },
                  },
                }}
                sx={{ maxWidth: "41vw" }}
              >
                <Box display={"flex"} alignItems={"center"} m={1}>
                  <Checkbox
                    checked={data?.data?.length === state.newTaskLeaders.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setState({
                          ...state,
                          newTaskLeaders: data?.data?.map((item: any) => item?.id),
                        });
                      } else {
                        setState({ ...state, newTaskLeaders: [] });
                      }
                    }}
                    sx={{ marginRight: "10px" }}
                  />
                  <Typography> Select All </Typography>
                </Box>
                {data?.data?.map((item: any) => (
                  <Box display={"flex"} alignItems={"center"} m={1}>
                    <Checkbox
                      checked={state.newTaskLeaders.indexOf(item?.id) > -1}
                      onChange={(e) => {
                        if (e.target.checked) {
                          if (state.newTaskLeaders.includes(item.id)) {
                            const userList = state.newTaskLeaders.filter(
                              (eachitem) => eachitem !== item.id
                            );
                            setState({
                              ...state,
                              newTaskLeaders: [...userList],
                            });
                          } else {
                            setState({
                              ...state,
                              newTaskLeaders: [...state.newTaskLeaders, item.id],
                            });
                          }
                        } else {
                          const userList = state.newTaskLeaders.filter(
                            (eachitem) => eachitem !== item.id
                          );
                          setState({
                            ...state,
                            newTaskLeaders: [...userList],
                          });
                        }
                      }}
                      sx={{ marginRight: "10px" }}
                    />
                    <Typography>{item?.fullName}</Typography>
                  </Box>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {state.selectedField === "remove_task_leader" && (
          <Grid item xs={6}>
            <FormControl fullWidth size="small" sx={{ flex: 1, maxWidth: "41vw" }}>
              <InputLabel id="demo-multiple-checkbox-label">Remove Task Leader</InputLabel>
              <Select
                multiple
                value={state.newTaskLeaders}
                input={<OutlinedInput label="New Task Leader" />}
                renderValue={(selected) => {
                  return data?.data
                    ?.filter((item: any) => selected.includes(item.id))
                    .map((item: any) => item.fullName)
                    .join(", ");
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: "300px",
                    },
                  },
                }}
                sx={{ maxWidth: "41vw" }}
              >
                <Box display={"flex"} alignItems={"center"} m={1}>
                  <Checkbox
                    checked={data?.data?.length === state.newTaskLeaders.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setState({
                          ...state,
                          newTaskLeaders: data?.data?.map((item: any) => item?.id),
                        });
                      } else {
                        setState({ ...state, newTaskLeaders: [] });
                      }
                    }}
                    sx={{ marginRight: "10px" }}
                  />
                  <Typography> Select All </Typography>
                </Box>
                {data?.data?.map((item: any) => (
                  <Box display={"flex"} alignItems={"center"} m={1}>
                    <Checkbox
                      checked={state.newTaskLeaders.indexOf(item?.id) > -1}
                      onChange={(e) => {
                        if (e.target.checked) {
                          if (state.newTaskLeaders.includes(item.id)) {
                            const userList = state.newTaskLeaders.filter(
                              (eachitem) => eachitem !== item.id
                            );
                            setState({
                              ...state,
                              newTaskLeaders: [...userList],
                            });
                          } else {
                            setState({
                              ...state,
                              newTaskLeaders: [...state.newTaskLeaders, item.id],
                            });
                          }
                        } else {
                          const userList = state.newTaskLeaders.filter(
                            (eachitem) => eachitem !== item.id
                          );
                          setState({
                            ...state,
                            newTaskLeaders: [...userList],
                          });
                        }
                      }}
                      sx={{ marginRight: "10px" }}
                    />
                    <Typography>{item?.fullName}</Typography>
                  </Box>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {/* //USERS */}
        {state.selectedField === "add_user" && (
          <Grid item xs={6}>
            <FormControl fullWidth size="small" sx={{ flex: 1, maxWidth: "41vw" }}>
              <InputLabel id="demo-multiple-checkbox-label">Add New Users</InputLabel>
              <Select
                multiple
                value={state.newMembers}
                // onChange={(e, v: any) => {
                //   setState({ ...state, members: e.target.value as string[] });
                // }}
                input={<OutlinedInput label="Add Users" />}
                renderValue={(selected) => {
                  return data?.data
                    ?.filter((item: any) => selected.includes(item.id))
                    .map((item: any) => item.fullName)
                    .join(", ");
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: "300px",
                    },
                  },
                }}
                sx={{ maxWidth: "41vw" }}
              >
                <Box display={"flex"} alignItems={"center"} m={1}>
                  <Checkbox
                    checked={data?.data?.length === state.newMembers.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setState({
                          ...state,
                          newMembers: data?.data?.map((item: any) => item?.id),
                        });
                      } else {
                        setState({ ...state, newMembers: [] });
                      }
                    }}
                    sx={{ marginRight: "10px" }}
                  />
                  <Typography> Select All </Typography>
                </Box>
                {data?.data?.map((item: any) => (
                  <Box display={"flex"} alignItems={"center"} m={1}>
                    <Checkbox
                      checked={state.newMembers.indexOf(item?.id) > -1}
                      onChange={(e) => {
                        if (e.target.checked) {
                          if (state.newMembers.includes(item.id)) {
                            const userList = state.newMembers.filter(
                              (eachitem) => eachitem !== item.id
                            );
                            setState({
                              ...state,
                              newMembers: [...userList],
                            });
                          } else {
                            setState({
                              ...state,
                              newMembers: [...state.newMembers, item.id],
                            });
                          }
                        } else {
                          const userList = state.newMembers.filter(
                            (eachitem) => eachitem !== item.id
                          );
                          setState({
                            ...state,
                            newMembers: [...userList],
                          });
                        }
                      }}
                      sx={{ marginRight: "10px" }}
                    />
                    <Typography>{item?.fullName}</Typography>
                  </Box>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {state.selectedField === "update_user" && (
          <Grid item xs={6}>
            <FormControl fullWidth size="small" sx={{ flex: 1, maxWidth: "41vw" }}>
              <InputLabel id="demo-multiple-checkbox-label">Update Users</InputLabel>
              <Select
                multiple
                value={state.newMembers}
                // onChange={(e, v: any) => {
                //   setState({ ...state, members: e.target.value as string[] });
                // }}
                input={<OutlinedInput label="Update Users" />}
                renderValue={(selected) => {
                  return data?.data
                    ?.filter((item: any) => selected.includes(item.id))
                    .map((item: any) => item.fullName)
                    .join(", ");
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: "300px",
                    },
                  },
                }}
                sx={{ maxWidth: "41vw" }}
              >
                <Box display={"flex"} alignItems={"center"} m={1}>
                  <Checkbox
                    checked={data?.data?.length === state.newMembers.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setState({
                          ...state,
                          newMembers: data?.data?.map((item: any) => item?.id),
                        });
                      } else {
                        setState({ ...state, newMembers: [] });
                      }
                    }}
                    sx={{ marginRight: "10px" }}
                  />
                  <Typography> Select All </Typography>
                </Box>
                {data?.data?.map((item: any) => (
                  <Box display={"flex"} alignItems={"center"} m={1}>
                    <Checkbox
                      checked={state.newMembers.indexOf(item?.id) > -1}
                      onChange={(e) => {
                        if (e.target.checked) {
                          if (state.newMembers.includes(item.id)) {
                            const userList = state.newMembers.filter(
                              (eachitem) => eachitem !== item.id
                            );
                            setState({
                              ...state,
                              newMembers: [...userList],
                            });
                          } else {
                            setState({
                              ...state,
                              newMembers: [...state.newMembers, item.id],
                            });
                          }
                        } else {
                          const userList = state.newMembers.filter(
                            (eachitem) => eachitem !== item.id
                          );
                          setState({
                            ...state,
                            newMembers: [...userList],
                          });
                        }
                      }}
                      sx={{ marginRight: "10px" }}
                    />
                    <Typography>{item?.fullName}</Typography>
                  </Box>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {state.selectedField === "remove_user" && (
          <Grid item xs={6}>
            <FormControl fullWidth size="small" sx={{ flex: 1, maxWidth: "41vw" }}>
              <InputLabel id="demo-multiple-checkbox-label">Remove Users</InputLabel>
              <Select
                multiple
                value={state.newMembers}
                // onChange={(e, v: any) => {
                //   setState({ ...state, members: e.target.value as string[] });
                // }}
                input={<OutlinedInput label="Remove User" />}
                renderValue={(selected) => {
                  return data?.data
                    ?.filter((item: any) => selected.includes(item.id))
                    .map((item: any) => item.fullName)
                    .join(", ");
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: "300px",
                    },
                  },
                }}
                sx={{ maxWidth: "41vw" }}
              >
                <Box display={"flex"} alignItems={"center"} m={1}>
                  <Checkbox
                    checked={data?.data?.length === state.newMembers.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setState({
                          ...state,
                          newMembers: data?.data?.map((item: any) => item?.id),
                        });
                      } else {
                        setState({ ...state, newMembers: [] });
                      }
                    }}
                    sx={{ marginRight: "10px" }}
                  />
                  <Typography> Select All </Typography>
                </Box>
                {data?.data?.map((item: any) => (
                  <Box display={"flex"} alignItems={"center"} m={1}>
                    <Checkbox
                      checked={state.newMembers.indexOf(item?.id) > -1}
                      onChange={(e) => {
                        if (e.target.checked) {
                          if (state.newMembers.includes(item.id)) {
                            const userList = state.newMembers.filter(
                              (eachitem) => eachitem !== item.id
                            );
                            setState({
                              ...state,
                              newMembers: [...userList],
                            });
                          } else {
                            setState({
                              ...state,
                              newMembers: [...state.newMembers, item.id],
                            });
                          }
                        } else {
                          const userList = state.newMembers.filter(
                            (eachitem) => eachitem !== item.id
                          );
                          setState({
                            ...state,
                            newMembers: [...userList],
                          });
                        }
                      }}
                      sx={{ marginRight: "10px" }}
                    />
                    <Typography>{item?.fullName}</Typography>
                  </Box>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {/* //APPROVAL */}
        {state.selectedField === "update_approval" && (
          <Grid item xs={12}>
            <Box>
              <Typography color="rgba(0, 0, 0, 0.54)" variant="caption">
                Approvals
              </Typography>
              {state?.approvalProcedures ? (
                <StyledSelectedBox sx={{ mt: "4px" }}>
                  <Typography variant="subtitle2">
                    {state?.approvalProcedures?.name} - Levels (
                    {state?.approvalProcedures?.approval?.approvalLevels?.length})
                  </Typography>
                  <IconButton onClick={() => setOpenSelectAppHier(true)} size="small">
                    <Edit fontSize="small" />
                  </IconButton>
                </StyledSelectedBox>
              ) : (
                <StyledSelectBox sx={{ mt: "4px" }} onClick={() => setOpenSelectAppHier(true)}>
                  <Typography variant="body1" color="rgba(0,0,0,0.5)">
                    Select Approval Hierarchy
                  </Typography>
                </StyledSelectBox>
              )}
            </Box>
          </Grid>
        )}

        {/* //UDIN */}
        {state.selectedField === "update_udin" && (
          <Grid item xs={12}>
            <Box>
              <Typography variant="h6" mt={1}>Do you Want to Add UDIN For this Task ? <Switch
                checked={state?.udinTask}
                sx={{ marginLeft: "20px" }}
                onChange={() => setState({...state, udinTask: !state.udinTask })}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
      <SelectApprovalHierarchy
        onChange={(data: any) => setState({ ...state, approvalProcedures: data, newValue: data?.id || null })}
        open={openSelectAppHier}
        setOpen={setOpenSelectAppHier}
        fromRecurring={true}
      />
      <RecurringFrequencyDetails
        open={recurringOpen}
        setOpen={setRecurringOpen}
        recurringDetails={data}
        recurringState={recurringState}
        setRecurringState={setRecurringState}
        saveState={state}
        setSaveState={setState}
      />
    </Paper>
  );
}

export default HandleSelectedField;
