import { Box, Breadcrumbs, Divider, Grid, IconButton, Radio, styled, Typography } from "@mui/material";
import { LinkRouter } from "components/BreadCrumbs";
import RouterLink from "components/RouterLink";
import useTitle from "hooks/useTitle";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useEffect, useState } from "react";
import { ResType } from "types";
import { useQuery } from "react-query";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

import { StyledContainer, StyledText1, StyledText2, StyledTitle } from "views/atom-pro/styles";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import Loader from "components/Loader";
import { getEproceedingFyiData } from "api/services/automation/income-tax";
import moment from "moment";
import EproceedingFyiNoticesList from "./EporceedingFyiNoticesList";
import AccessDenied from "./Access/accessDenide";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";


interface StyledSpanProps {
  bgColor?: string; // Define bgColor prop here
}

const StyledSpan = styled("span")<StyledSpanProps>(({ theme, bgColor }) => ({
  backgroundColor: bgColor || "#ffffff",
  padding: "3px",
  fontSize: "16px",
  fontWeight: "500",
  borderRadius: "5px",
  color: "#222222",
}));

const handleItemClick = (fileName, type) => {
  const urlStart = `https://jss-vider-bucket.s3.ap-south-1.amazonaws.com/`;
  const amazon = type == "AMAZON" || type == null
  const url = amazon ? (urlStart + fileName) : fileName?.key;
  window.open(url, '_blank');
};

const getFileName = (filePath, type) => {
  // Split the filePath by '/' and get the last part which is the file name
  if (type == 'AMAZON' || type == null) {
    const parts = filePath.split('/');
    return parts[parts.length - 1];
  } else if (type == 'MICROSOFT') {
    const parts = filePath['name'].split('/');
    return parts[parts.length - 1];
  };
  return null

};


function EproceedingFyiView() {
  useTitle("E-Proceedings (For Your Information)");
  const params = useParams();
  const navigate = useNavigate();

  const [state, setInitialState] = useState<any>({});

  const { data, isLoading: demandIsLoading }: ResType = useQuery(
    ["e-proceeding", params.id],
    getEproceedingFyiData, {
    onSuccess: (res) => {
      setInitialState(res?.data)
    }
  }

  );

  const TimelineComponent = ({ dateOfDemandRaised }) => {
    return (
      <Timeline position="left">
        {dateOfDemandRaised.map((item, index) => {
          const { completed, date, status } = item;
          return (
            <TimelineItem key={index}>
              <TimelineOppositeContent
                sx={{ m: "auto 0" }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                <Typography variant="body2" component="span">
                  {status}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot
                  sx={{
                    backgroundColor: completed ? "#4caf50" : "#ff9800", // Green if completed, orange otherwise
                    borderRadius: "50%",
                    width: "16px",
                    height: "16px",
                    boxShadow: "inset 0 0 0 2px #fff",
                  }}
                />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography variant="h6" component="span">
                  {moment(date).format("DD MMM YYYY")}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    );
  };
  if (demandIsLoading) return <Loader />;

  return (
    <>
      {!state && (
        <AccessDenied />
      )}
      {state && (
        <Box display="flex" height="80vh">
          <Grid container>
            <Grid item xs={8} sx={{ borderRight: "1px solid #d9d9d9" }}>
              <Box height="80vh" overflow="auto" mb={2}>
                <Box>
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBackIcon fontSize="small" />
                    <Typography variant="subtitle2" ml={1}>e-Proceedings</Typography>
                  </IconButton>
                </Box>
                <Typography
                  sx={{ marginBottom: "20px", color: "#2F343A", paddingLeft: "10px" }}
                  variant="subtitle1"
                >
                  {state?.proceedingName}
                </Typography>
                <Typography
                  sx={{ marginBottom: "20px", color: "#7C848E", paddingLeft: "10px" }}
                  variant="subtitle2"
                >
                  Acknowledgement No:{state?.acknowledgementNo}
                </Typography>

                <Grid container spacing={2} pl={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Box
                          style={{
                            border: "1.5px solid #d1d1e0",
                            padding: "10px",
                            marginBottom: "10px",
                            borderRadius: "7px",
                          }}
                        >
                          <StyledText1 variant="h6" color="primary" mb={1}>
                            ITR Type
                          </StyledText1>
                          <StyledSpan
                          // bgColor={getRandomColor()}
                          >
                            {state?.itrType ? state?.itrType : '--'}
                          </StyledSpan>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box
                          style={{
                            border: "1.5px solid #d1d1e0",
                            padding: "10px",
                            marginBottom: "10px",
                            borderRadius: "7px",
                          }}
                        >
                          <StyledText1 variant="h6" color="primary" mb={1}>
                            Last Response Date
                          </StyledText1>
                          <StyledSpan
                          // bgColor={getRandomColor()}
                          >
                            {state?.lastResponseSubmittedOn ? moment(state?.lastResponseSubmittedOn).format('DD MMM YYYY') : '--'}
                          </StyledSpan>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box
                          style={{
                            border: "1.5px solid #d1d1e0",
                            padding: "10px",
                            marginBottom: "10px",
                            borderRadius: "7px",
                          }}
                        >
                          <StyledText1 variant="h6" color="primary" mb={1}>
                            Notice Section
                          </StyledText1>
                          <StyledSpan
                          // bgColor={getRandomColor()}
                          >
                            {state?.noticeName ? state?.noticeName : '--'}
                          </StyledSpan>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Box>
                  <Box mb={2}>
                    <Box padding={1} sx={{ backgroundColor: "#F6F6F6" }}>
                      <Typography variant="subtitle2">Closure Order Attachments</Typography>
                    </Box>
                    {state?.closureOrderAttatchments && state?.closureOrderAttatchments.map((detail: any, index: number) => (
                      <Box display="flex" textAlign="center">
                        <Typography style={{ padding: '5px', margin: '5px' }}>{index + 1}.</Typography>
                        <div
                          key={index}
                          style={{ cursor: 'pointer', padding: '5px', margin: '5px', border: '1px solid #ccc', borderRadius: '5px', color: "blue" }}
                          onClick={() => handleItemClick(detail, state?.storageSystem)}
                        >
                          {getFileName(detail, state?.storageSystem)}
                        </div>
                      </Box>

                    ))}
                  </Box>
                  <Box padding={1} sx={{ backgroundColor: "#F6F6F6" }}>
                    <Typography variant="subtitle2">Notices</Typography>
                  </Box>

                  <EproceedingFyiNoticesList data={state?.notices} />


                </Box>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="subtitle2" sx={{ color: "#667085" }}>
                Timeline
              </Typography>
              {state && state.proceedingStatusDetails && (
                <TimelineComponent dateOfDemandRaised={state?.proceedingStatusDetails} />
              )}

            </Grid>
          </Grid>
        </Box>

      )}

    </>
  );
}

export default EproceedingFyiView;
