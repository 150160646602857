import { DatePicker } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { getCategories } from "api/services/categories";
import { getClients, getTaskCreateClients } from "api/services/clients/clients";
import { getServices } from "api/services/services";
import { getUsersForReport } from "api/services/users";
import Loader from "components/Loader";
import { usePermissions } from "context/PermissionsProvider";
import {
  PriorityEnum,
  RecurringFields,
} from "data/constants";
import { IState } from "pages/settings/bulk-activity/recurring-profile-bulk-activity";
import { useState } from "react";
import { useQuery } from "react-query";
import { ResType } from "types";
import { getTitle } from "utils";
import { Permissions } from "data/permissons";
import { mulitipleClient } from "assets";
import { snack } from "components/toast";
import CampaignIcon from "@mui/icons-material/Campaign";
import BulkRecurringInfo from "./BulkRecurringInfo";

interface Props {
  state: IState;
  initialState: IState;
  setState: (state: IState) => void;
  onSubmit: () => void;
  isloading: boolean;
  clearFilters: () => void;
  setData: (data) => void;
  handleViewMore: () => void;
}

enum RecurringFrequency {
  MONTHLY = "monthly",
  QUARTERLY = "quarterly",
  HALF_YEARLY = "half_yearly",
  YEARLY = "yearly",
}

function Filters({ state, setState, isloading, onSubmit, clearFilters, handleViewMore, setData, initialState }: Props) {
  const { data, isLoading }: ResType = useQuery(["users"], getUsersForReport);
  const [openInfo, setOpenInfo] = useState(false);

  const { data: clients, isLoading: clientsLoading }: ResType = useQuery(
    ["clients"],
    getTaskCreateClients,
  );

  const { data: services, isLoading: serviceLoading }: ResType = useQuery(
    ["services", {}],
    getServices
  );

  const { data: categories }: ResType = useQuery("categories", getCategories);
  let { permissions, role } = usePermissions();

  const isPermission = (name) => {
    if (role?.defaultRole) {
      return true;
    }
    if (Array.isArray(name)) {
      let isPermitted = name.some((item) => permissions.includes(item));
      return isPermitted ? true : false;
    }
    if (permissions.includes(name)) {
      return true;
    }
    return false;
  };

  const getSubCategories = categories?.data?.find(
    (item: any) => item.id === state.category?.id
  )?.subCategories;

  const taskPriorityOptions = Object.keys(PriorityEnum).map((key) => ({
    label: key
      .replace(/_/g, " ")
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase()),
    value: PriorityEnum[key],
  }));



  if (isLoading || clientsLoading || serviceLoading) return <Loader />;

  return (
    <Paper sx={{ mt: 1, p: 2, py: 2 }}>
      <Grid container spacing={2}>

        {/* CLIENT */}
        <Grid item xs={4}>
          <Autocomplete
            disablePortal
            value={state.client}
            onChange={(e, v: any) => {
              setState({ ...state, client: v })
              setData(null);
            }
            }
            options={clients?.data?.result || []}
            getOptionLabel={(option: any) => option?.displayName}
            renderInput={(params) => <TextField {...params} size="small" label="Client / Client Group" />}
            renderOption={(props, option) =>
              option.type ? (
                <li
                  {...props}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography>
                    {option?.displayName}
                    <img
                      src={mulitipleClient}
                      width={"22px"}
                      style={{ marginLeft: "10px", marginRight: "10px" }}
                      alt="group"
                    />
                  </Typography>
                </li>
              ) : (
                <li {...props}>{option?.displayName}</li>
              )
            }
          />
        </Grid>

        {/* ASSIGNEE */}
        <Grid item xs={4}>
          <Autocomplete
            disablePortal
            value={state.members}
            onChange={(e, v: any) => {
              setState({ ...state, members: v })
              setData(null);
            }
            }
            options={data?.data}
            getOptionLabel={(option: any) => option?.fullName}
            renderInput={(params) => <TextField {...params} size="small" label="Assignee *" />}
          />
        </Grid>

        {/* TASK LEADER */}
        <Grid item xs={4}>
          <Autocomplete
            disablePortal
            value={state.taskLeader}
            onChange={(e, v: any) => {
              setState({ ...state, taskLeader: v })
              setData(null);
            }
            }
            options={["NA", ...data?.data]}
            getOptionLabel={(option: any) => option?.fullName || "NA"}
            renderInput={(params) => <TextField {...params} size="small" label="Task Leader" />}
          />
        </Grid>

        {/* Service Category */}
        <Grid item xs={4}>
          <Autocomplete
            disablePortal
            value={state.category}
            onChange={(e, v: any) => {
              setState({ ...state, category: v });
              setData(null);
            }}
            options={categories?.data || []}
            getOptionLabel={(option: any) => option?.name}
            renderInput={(params) => (
              <TextField {...params} size="small" label="Service Category" />
            )}
          />
        </Grid>
        {getSubCategories?.length > 0 && (
          <Grid item xs={4}>
            <Autocomplete
              disablePortal
              value={state.subCategory}
              onChange={(e, v: any) => {
                setState({ ...state, subCategory: v })
                setData(null);
              }
              }
              options={getSubCategories || []}
              getOptionLabel={(option: any) => option?.name}
              renderInput={(params) => (
                <TextField {...params} size="small" label="Service Sub-Category" />
              )}
            />
          </Grid>
        )}
        {/* SERVICE NAME */}
        <Grid item xs={4}>
          <Autocomplete
            disablePortal
            value={state.service}
            onChange={(e, v: any) => {
              setState({ ...state, service: v })
              setData(null);
            }
            }
            options={services?.data?.result || []}
            getOptionLabel={(option: any) => option?.name}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            )}
            renderInput={(params) => <TextField {...params} size="small" label="Service Name" />}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Frequency"
            select
            size="small"
            fullWidth
            value={state.frequency}
            onChange={(e) => {
              setState({ ...state, frequency: e.target.value })
              setData(null);
            }}
          >
            {Object.values(RecurringFrequency).map((item) => (
              <MenuItem key={item} value={item}>
                {getTitle(item)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {/* PRIORITY */}
        <Grid item xs={4}>
          <Autocomplete
            disablePortal
            multiple
            value={state.priority || []}
            onChange={(e, v: any) => {
              const uniqueValues = Array.from(new Set(v.map((item) => item.value))).map((value) =>
                v.find((item) => item.value === value)
              );
              setState({ ...state, priority: uniqueValues });
              setData(null);
            }}
            options={taskPriorityOptions}
            getOptionLabel={(option: any) => option.label}
            renderInput={(params) => <TextField {...params} size="small" label="Task Priority" />}
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="space-between" alignItems='center'>
        <Button
          onClick={onSubmit}
          disabled={isloading}
          color="secondary"
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
        >
          Generate Recurring Profiles
        </Button>
        <Box display='flex' justifyContent='space-between'>
          <Grid item xs={2}>
            <Button
              onClick={clearFilters}
              sx={{ marginLeft: "10px", padding: "6px", marginRight: "6px" }}
              size="small"
              color="secondary"
              variant="outlined"
            >
              Reset Filters
            </Button>
            <Button onClick={() => setOpenInfo(true)} variant="outlined">
              <CampaignIcon />
            </Button>
          </Grid>
        </Box>
      </Box>

      <Grid container spacing={2}>
        {(isPermission(Permissions.VIEW_ALL_TASKS) ||
          (isPermission(Permissions.VIEW_ASSIGNED_TASKS) && isPermission(Permissions.EDIT_TASK))) && (
            <Grid item xs={4}>
              <TextField
                select
                label="Select Task Field"
                fullWidth
                size="small"
                name="selectedField"
                value={state.selectedField}
                onChange={(e: any) => {
                  if (e.target.value === "update_dates") {
                    if (!state.service) {
                      return snack.error("For Update Dates Please Select Service !");
                    } else if (!state.frequency) {
                      return snack.error("For Update Dates Please Select Frequncy !");
                    } else {
                      setState({
                        ...state,
                        selectedField: e.target.value,
                        newValue: null,
                        existingValue: null,
                      });
                    }
                  } else {
                    setState({
                      ...state,
                      selectedField: e.target.value,
                      newValue: null,
                      existingValue: null,
                    });
                  }
                }}
              >
                {RecurringFields.map((item) => (
                  <MenuItem key={item.label} value={item.value}>
                    {item.value === "update_udin" ? item.label : getTitle(item.value)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
      </Grid>
      <BulkRecurringInfo open={openInfo} setOpen={setOpenInfo} />
    </Paper>
  );
}

export default Filters;
