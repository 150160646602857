import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import useTitle from "hooks/useTitle";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { ResType } from "types";
import { useQuery } from "react-query";
import { StyledText1 } from "views/atom-pro/styles";
import Loader from "components/Loader";
import { getIncomeTaxEproceedingNoticeFyi } from "api/services/automation/income-tax";
import moment from "moment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccessDenied from "./Access/accessDenide";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";


interface StyledSpanProps {
  bgColor?: string; // Define bgColor prop here
}

const StyledSpan = styled("span")<StyledSpanProps>(({ theme, bgColor }) => ({
  backgroundColor: bgColor || "#fffff",
  padding: "3px",
  fontSize: "16px",
  fontWeight: "500",
  borderRadius: "5px",
  color: "#222222",
}));

function FYINoticeView() {
  useTitle("e-Proceedings (For Your Information) Notice");
  const params = useParams();
  const navigate = useNavigate();
  const [state, setInitialState] = useState<any>({});
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleToggle = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  const { data, isLoading: demandIsLoading }: ResType = useQuery(
    ["e-proceeding-notice", params.itemId],
    getIncomeTaxEproceedingNoticeFyi,
    {
      onSuccess: (res) => {
        setInitialState(res?.data);
      },
    }
  );

  const generateAssessmentYear = (assessmentYear) => {
    if (!assessmentYear || assessmentYear === "0" || assessmentYear === "") {
      return "NA";
    } else if (!isNaN(assessmentYear) && assessmentYear.length === 4) {
      const year = parseInt(assessmentYear);
      const nextYear = (year + 1).toString().slice(-2);
      return `AY ${year}-${nextYear}`;
    } else {
      return assessmentYear;
    }
  };

  const getFileName = (filePath, type) => {
    // Split the filePath by '/' and get the last part which is the file name
    if (type == 'AMAZON' || type == null) {
      const parts = filePath.split('/');
      return parts[parts.length - 1];
    } else if (type == 'MICROSOFT') {
      const parts = filePath['name'].split('/');
      return parts[parts.length - 1];
    } else if (type == 'BHARATHCLOUD') {
      const parts = filePath['key'].split('/');
      return parts[parts.length - 1];

    }
    return null

  };

  const handleItemClick = (fileName, type) => {
    const urlStart = `https://jss-vider-bucket.s3.ap-south-1.amazonaws.com/`;
    const amazon = type == "AMAZON" || type == null;
    const bharachCloud = type == "BHARATHCLOUD" || type == null;
    const url = amazon ? (urlStart + fileName) : bharachCloud ? (`${fileName['endPoint']}/${fileName['key']}`) : fileName?.key;
    window.open(url, '_blank');
  };

  const renderGridItem = (label: string, value: any) => (
    <>
      <Grid item xs={4}>
        <Typography variant="h6">{label}</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="h6">:</Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography>{value != null ? value : "--"}</Typography>
      </Grid>
    </>
  );

  if (demandIsLoading) return <Loader />;
  return (
    <>
      {!state && <AccessDenied />}
      {state && (
        <>
          <Box>
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon fontSize="small" />
              <Typography variant="subtitle2" ml={1}>Notices</Typography>
            </IconButton>
          </Box>
          <Box height="75vh" overflow="auto" mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Grid container spacing={1} pl={2} pr={2}>
                  {renderGridItem("Proceeding Name", state?.proceedingName)}
                  {renderGridItem("Name Of Assesse", state?.nameOfAssesse)}
                  {renderGridItem("DIN", state?.documentIdentificationNumber)}
                  {renderGridItem("Reference Id", state?.documentReferenceId)}
                  {renderGridItem("PAN", state?.pan)}
                  {renderGridItem("Assessment Year", `${generateAssessmentYear(state?.assesmentYear)}`)}
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1} pl={2} pr={2}>
                  {renderGridItem("Notice Section", `${state?.noticeSection ? state?.noticeSection : "--"}`)}
                  {renderGridItem("ITR Type", `${state?.itrType ? state?.itrType : "--"}`)}
                  {renderGridItem("Issued On", `${state?.issuedOn ? moment(state?.issuedOn).format("DD MMM YYYY") : "--"}`)}
                  {renderGridItem("Response Due Date", `${state?.responseDueDate ? moment(state.responseDueDate).format("DD MMM YYYY") : "--"}`)}
                  {renderGridItem("Type", `${state?.eProceeding?.type}`)}
                </Grid>
              </Grid>
            </Grid>
            <Box>
              <Box>
                <Box display="flex" p={1} pl={2} pr={2} pb={2}>
                  <Typography sx={{ color: "#2F2F2F", fontWeight: 600 }} variant="body2">
                    Description :-
                  </Typography>
                  <Typography>
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{ backgroundColor: "#EAFFE7", fontWeight: 600 }}
                    >
                      {state?.description}
                    </Box>
                  </Typography>
                </Box>
                <Box padding={1} sx={{ backgroundColor: "#F6F6F6" }}>
                  <Typography variant="subtitle2">Notice/Letter</Typography>
                </Box>

                <div>
                  {state?.noticeLetters &&
                    state?.noticeLetters.map((detail: any, index: number) => (
                      <Box display="flex" textAlign="center">
                        <Typography style={{ padding: "5px", margin: "5px" }}>
                          {index + 1}.
                        </Typography>
                        <div
                          key={index}
                          style={{
                            cursor: "pointer",
                            padding: "5px",
                            margin: "5px",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            color: "blue",
                          }}
                          onClick={() => handleItemClick(detail, state?.storageSystem)}
                        >
                          {getFileName(detail, state?.storageSystem)}
                        </div>
                      </Box>
                    ))}
                </div>
                <Box padding={1} sx={{ backgroundColor: "#F6F6F6" }}>
                  <Typography variant="subtitle2">Notice Response</Typography>
                </Box>
                {state?.responses?.map((item: any, index: any) => (
                  <Accordion
                    expanded={expanded === `panel${index}`}
                    onChange={handleToggle(`panel${index}`)}
                    key={index}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index}bh-content`}
                      id={`panel${index}bh-header`}
                    >
                      <Typography variant="h6">
                        Response {index + 1},{" "}
                        {item?.submittedOn
                          ? moment(item?.submittedOn).format("DD MMM YYYY HH:mm")
                          : "--"}
                        ,{" "}
                        {item?.responseType === "FR"
                          ? "Full Response"
                          : item?.responseType === "PR"
                            ? "Partial Response"
                            : "--"}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box display="flex" gap={2}>
                        <Grid item xs={12} p={2}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Box
                                style={{
                                  border: "1.5px solid #d1d1e0",
                                  padding: "10px",
                                  marginBottom: "10px",
                                  borderRadius: "7px",
                                }}
                              >
                                <StyledText1 variant="h6" color="primary" mb={1}>
                                  Response/Remarks
                                </StyledText1>
                                <StyledSpan
                                // bgColor={getRandomColor()}
                                >
                                  {item.remarks ? item?.remarks : "--"}
                                </StyledSpan>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box
                                style={{
                                  border: "1.5px solid #d1d1e0",
                                  padding: "10px",
                                  marginBottom: "10px",
                                  borderRadius: "7px",
                                }}
                              >
                                <StyledText1 variant="h6" color="primary" mb={1}>
                                  Remark Submission On
                                </StyledText1>
                                <StyledSpan
                                // bgColor={getRandomColor()}
                                >
                                  {item?.submittedOn
                                    ? moment(item?.submittedOn).format("DD MMM YYYY HH:mm")
                                    : "--"}
                                </StyledSpan>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box
                                style={{
                                  border: "1.5px solid #d1d1e0",
                                  padding: "10px",
                                  marginBottom: "10px",
                                  borderRadius: "7px",
                                }}
                              >
                                <StyledText1 variant="h6" color="primary" mb={1}>
                                  Response Type
                                </StyledText1>
                                <StyledSpan
                                // bgColor={getRandomColor()}
                                >
                                  {item?.responseType === "FR"
                                    ? "Full Response"
                                    : item?.responseType === "PR"
                                      ? "Partial Response"
                                      : "--"}
                                </StyledSpan>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Box padding={1} sx={{ backgroundColor: "#F6F6F6" }}>
                                <Typography variant="subtitle2">Attachments</Typography>
                              </Box>

                              <Grid container spacing={1}>
                                {item?.attachments &&
                                  item?.attachments.map((detail: any, index: number) => (
                                    <Grid item xs={12} sm={6} md={4} key={index}>
                                      <Box display="flex" textAlign="left" alignItems="center">
                                        <Typography style={{ padding: "3px", margin: "2px" }}>
                                          {index + 1}.
                                        </Typography>
                                        <div
                                          key={index}
                                          style={{
                                            cursor: "pointer",
                                            padding: "3px",
                                            margin: "2px",
                                            border: "1px solid #ccc",
                                            borderRadius: "5px",
                                            color: "blue",
                                          }}
                                          onClick={() =>
                                            handleItemClick(detail, item?.storageSystem)
                                          }
                                        >
                                          {getFileName(detail, item?.storageSystem)}
                                        </div>
                                      </Box>
                                    </Grid>
                                  ))}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

export default FYINoticeView;
