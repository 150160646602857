import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  Box,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as XLSX from "xlsx";

function ErrorsModal({ errorsList, onClose, sucessCount, failCount}) {
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      errorsList.map((error) => ({
        "Task ID": error.taskId,
        "Task Name": error.taskName,
        Error: error.error,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Errors");
    XLSX.writeFile(workbook, "task-bulk-activity-errors-list.xlsx");
  };

  return (
    <Dialog open={errorsList?.length > 0} onClose={onClose}>
      <DialogTitle>Errors List</DialogTitle>
      <Box display='flex'  justifyItems='center'>
      <DialogTitle color='green'>Success Count:{sucessCount}</DialogTitle>
      <DialogTitle color='secondary'>Failure Count:{failCount}</DialogTitle>
      </Box>
      <Box mr={2} display="flex" justifyContent="flex-end">
        <Button variant="contained" color="primary" onClick={exportToExcel}>
          Export to Excel
        </Button>
      </Box>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <List>
          {errorsList.map((error, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={`Task ID: ${error.clientId}`}
                secondary={
                  <>
                    <Typography variant="body1">Task Name : {error.taskName}</Typography>
                    <Typography variant="body2" color="error">
                      {" "}
                      Error :{error.error}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}

export default ErrorsModal;
