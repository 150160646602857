import { http } from "api/http";

const getApprvalHeirarchies = ({ queryKey }) => {
    return http.get("/approval-level", { params: { ...queryKey[1] } });
};
const getApprovalLevels = ({ queryKey }) => {
    return http.get("/approval-level/tasks", { params: { ...queryKey[1] } });
};

const updateApprovalLevel = (data: any) => {
    const { approvalId, status, remarks, taskId, level } = data;
    return http.put(`/approval-level/status-change`, {
        status,
        remarks,
        approvalId,
        taskId,
        level
    });
};

export {
    getApprvalHeirarchies,
    getApprovalLevels,
    updateApprovalLevel

}