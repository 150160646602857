import { Button, Grid, TextField } from "@mui/material";
import { updateAutClientCredentials } from "api/services/automation/income-tax";
import DrawerWrapper from "components/DrawerWrapper";
import Loader from "components/Loader";
import { snack } from "components/toast";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

const CredentialsEdit = ({ open, setOpen, data }) => {
  const queryClient = useQueryClient();
  const [state, setState] = useState(data);

  useEffect(() => {
    setState(data);
  }, [data]);

  const handleChange = (e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const { mutate, isLoading } = useMutation(updateAutClientCredentials, {
    onSuccess: (res) => {
      snack.success("Client Income Tax Creadentials Updated");
      setTimeout(() => {
        queryClient.invalidateQueries("get-Clients-Credentials");
      }, 500);
      setOpen(false);
    },
    onError: (err: any) => {
      if (err && err?.response && err?.response?.data && err?.response?.data?.message) {
        snack.error(err.response.data.message);
      } else if (!err.error) {
        snack.error("An error occurred while updating Client Income Tax Creadentials");
      }
    },
  });

  const handleChangePassword = () => {
    mutate({
      id: state.id,
      data: state,
    });
  };

  if (isLoading) return <Loader />;
  return (
    <DrawerWrapper title="Income Tax Password Edit" open={open} setOpen={setOpen}>
      <Grid container spacing={2}>
        <Grid item xs={12} mt={2}>
          <TextField
            fullWidth
            size="small"
            label="Client Category"
            disabled={true}
            name=""
            value={state?.client?.category || ""}
            inputProps={{ maxLength: 100 }}
            required
          />
        </Grid>
        <Grid item xs={12} mt={2}>
          <TextField
            fullWidth
            size="small"
            label="Client Name"
            disabled={true}
            name=""
            value={state?.client?.displayName || ""}
            inputProps={{ maxLength: 100 }}
            required
          />
        </Grid>
        <Grid item xs={12} mt={1}>
          <TextField
            // onChange={handleChange}
            size="small"
            fullWidth
            disabled={true}
            label="PAN"
            name="panNumber"
            value={state?.panNumber || ""}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>

        <Grid item xs={12} mt={1}>
          <TextField
            onChange={handleChange}
            fullWidth
            size="small"
            label="Password"
            name="password"
            value={state?.password || ""}
            inputProps={{ maxLength: 50 }}
            required
          />
        </Grid>

        <Grid item xs={12}>
          <Button onClick={handleChangePassword} fullWidth color="secondary" variant="contained">
            Update
          </Button>
        </Grid>
      </Grid>
    </DrawerWrapper>
  );
};

export default CredentialsEdit;