import { Box, Grid, IconButton, List, ListItem, Typography } from "@mui/material"
import { useQuery, useQueryClient } from "react-query";
import { ResType } from "types";
import { useParams } from "react-router-dom";
import { getClientAutoStatus } from "api/services/automation/income-tax";
import CredentialsEdit from "./CreadentialEdit";
import { useState } from "react";
import { Edit } from "@mui/icons-material";
import AccessDenied from "./Access/accessDenide";
import Loader from "components/Loader";
import ValidateAccess from "components/ValidateAccess";
import { Permissions } from "data/permissons";

const ClientAutoStatus = () => {
    const params = useParams();
    const queryClient = useQueryClient();
    const [open, setOpen] = useState(false)
    const [state, setState] = useState<any>({})
    const [checkAccess, setCheckAccess] = useState(true)
    const [clientState, setClientState] = useState<any>("")
    const [inqueueCount, setInqueueCount] = useState<any>(0)

    const { data, isLoading }: ResType = useQuery(
        ["Client-Auto-Status", params.incometaxid],
        getClientAutoStatus, {
        onSuccess: (res) => {
            setCheckAccess(res?.data?.accessDenied)
            setClientState(res?.data?.lastCompletedMachine)
            setState(res?.data?.lastCompletedMachine?.autoCredentials)
            setInqueueCount(res?.data?.totalInqueueCount)

        },
    }
    );

    let timeoutId = setTimeout(() => {
        queryClient.invalidateQueries("Client-Auto-Status");
    }, 10000);

    // Stop the timeout if condition becomes true
    if (clientState?.status === "COMPLETED" || !checkAccess) {
        clearTimeout(timeoutId);
    }
    if (isLoading) return <Loader />
    return (
        <>
            {!checkAccess && (
                <AccessDenied />
            )}
            {checkAccess && (
                <Box m={2} height="75vh" overflow="auto">
                    <Box height="70%">
                        <Box display="flex" justifyContent="space-between">
                            <Box>
                                <Typography variant="h5">Client Income Tax Status</Typography>

                            </Box>
                            <Box mr={3}>
                                {clientState?.remarks === "Invalid Password, Please retry." && (
                                    <Box>
                                        <ValidateAccess name={Permissions.PASSWORD_EDIT_INCOMETAX}>
                                            <IconButton onClick={(e) => setOpen(true)}>
                                                <Edit />
                                            </IconButton>
                                        </ValidateAccess>

                                    </Box>
                                )}
                            </Box>
                        </Box>

                        <Typography mt={1} variant="h6"> Status : {clientState?.status}</Typography>
                        <Typography mt={1} variant="h6"> Remarks : {clientState?.remarks}</Typography>
                        {clientState && clientState?.status === "INQUEUE" && (
                            <Typography variant="h6">Present Inqueue Count : {inqueueCount}</Typography>
                        )}
                        <Box height="70%" overflow="auto">
                            <Box display={"flex"} justifyContent="center" alignItems="center" mt={5}>
                                <Box width="100%">
                                    {clientState && (
                                        <Grid container>

                                            {clientState?.completeModules?.map(item => (
                                                <Grid item xs={3.8} mt={1} m={1} bgcolor={(item?.startsWith("No")) ? "#ffe6f2" : item?.startsWith("Error in") ? "#ff8080" : "#e6ffff"} p={1.5} spacing={2} borderRadius="5px" >
                                                    <Typography key={item} textAlign="center">{item}</Typography>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    )}
                                </Box>
                            </Box>
                            <Box>
                                {(clientState?.remarks === "OTP Base LogIn" || clientState?.remarks === "Login attempt was unsuccessful due to Multi-Factor Authentication") && (
                                    <Box sx={{ padding: 2, backgroundColor: '#f9f9f9', borderRadius: 2 }}>
                                        <Typography variant="h6" component="h2" gutterBottom>
                                            Important Information:
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            The automated data extraction process within ATOM Pro's Income Tax PAN Module functions best when client logins don't utilize Multi-Factor Authentication (MFA) methods;
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            When adding clients to ATOM Pro (Income Tax PAN Module), please ensure the client login does not use any of the following Multi-Factor Authentication (MFA) methods like:
                                        </Typography>
                                        <List>
                                            <ListItem >
                                                1. e-Filing Vault Higher Security (Net Banking | DSC | Aadhaar OTP | Bank Account EVC | Demat Account EVC)
                                            </ListItem>
                                            <ListItem>
                                                2. Static Password
                                            </ListItem>
                                            <ListItem>
                                                3. Aadhaar OTP Login (Single Factor Authentication)
                                            </ListItem>
                                        </List>
                                        <Typography variant="body1" gutterBottom>
                                            <span style={{ color: "red", fontWeight: "600" }} > Reason:</span>   Automation processes running in the background rely on simpler login methods and may not be able to handle these complex MFA methods. Using these methods can lead to disruptions or errors during data extraction. Sometimes these external factors affect the timely delivery of OTP/EVC and delayed or expired codes during automation can cause disruptions and erroneous data extraction.
                                        </Typography>
                                        <Typography variant="body1">
                                            For a smooth automation process, please ensure you use a login method without MFA.
                                        </Typography>
                                    </Box>
                                )

                                }
                            </Box>
                        </Box>
                    </Box>
                    <Box height="30%">
                        <Grid item xs={12} >
                            <Typography variant="h6" color="primary" display="inline" fontSize={14}> The Fine Print</Typography>
                            <Typography variant="inherit" color="#000000" fontWeight={"700"} mt={0.5} fontSize={12}>
                                As advised by our legal team, we are obligated to disclose the following information. This disclosure is intended to ensure transparency and provide you with the necessary details to
                                make informed decisions. We cannot guarantee that the service will always function without disruptions, errors, or delays. Several factors may impact the data fetched from
                                the website. They may result in failure including delay of data, but not limited to, changes, downtime, and maintenance in the tax compliance website. Vider Business Solutions Pvt Ltd is not
                                responsible for disruptions, delays, or failures caused by factors over which we have no control.
                                So tell us your experience or if any issues arise, please report them immediately so we can address them promptly.
                                We are committed to addressing any issues within our control. We appreciate your
                                understanding of factors that are completely beyond/out of our control.
                            </Typography>

                        </Grid>

                    </Box>




                    <CredentialsEdit open={open} setOpen={setOpen} data={data?.data?.lastCompletedMachine?.autoCredentials} />
                </Box>

            )}
        </>

    )
}

export default ClientAutoStatus