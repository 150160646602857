import { Box, Button, Paper, Typography } from "@mui/material";

import Loader from "components/Loader";
import Members from "components/Members";
import PriorityText from "components/PriorityText";
import Table, { ColumnType } from "components/Table";
import { usePermissions } from "context/PermissionsProvider";
import { useNavigate } from "react-router-dom";
import { getTitle } from "utils";
import { Permissions } from "data/permissons";
import { mulitipleClient } from "assets";


function Report({
  data,
  state,
  setState,
  isLoading,
  isError,
  onBulkUpdate,
  limit,
  offset,
  handlePageChange,
  handleLimitChange,
  selected,
  setSelected,
  bulkUpdationLoading,
  handleSubmit,
}) {
  const navigate = useNavigate();
  let { permissions, role } = usePermissions();

  const isPermission = (name) => {
    if (role?.defaultRole) {
      return true;
    }
    if (Array.isArray(name)) {
      let isPermitted = name.some((item) => permissions.includes(item));
      return isPermitted ? true : false;
    }
    if (permissions.includes(name)) {
      return true;
    }
    return false;
  };

  if (isLoading || bulkUpdationLoading) return <Loader />;

  if (isError) return null;

  if (!data?.result) return null;

  return (
    <>
      <Paper sx={{ mt: 2, pl: 2, pr: 2 }}>
        <Box display="flex" justifyContent="space-between" mb={1} alignItems="center">
          {(isPermission(Permissions.VIEW_ALL_TASKS) ||
            (isPermission(Permissions.VIEW_ASSIGNED_TASKS) && isPermission(Permissions.EDIT_TASK))) && (
              <Box m={1} ml="auto">
                <Button
                  variant="outlined"
                  color="secondary"
                  disabled={isLoading}
                  onClick={() => onBulkUpdate(selected)}
                >
                  Bulk Update
                </Button>
              </Box>)}
        </Box>
        <Table
          loading={false}
          data={data?.result || []}
          selection={{ selected, setSelected }}
          columns={columns}
          onRowClick={(v) => navigate(`/task-board/${v?.id}/?clientId=${v?.client?.clientId}`)}
          pagination={{
            totalCount: data?.totalCount || 0,
            pageCount: limit,
            setPageCount: (newLimit) => {
              handleLimitChange(newLimit)
            },
            page: Math.floor(offset / limit),
            setPage: (page) => {
              handlePageChange(page);
            },
          }}
        />
      </Paper>
    </>
  );
}

const columns: Array<ColumnType> = [
  {
    key: "client.displayName", title: "Client / Client Group",
    render: (row: any) => {
      return (
        row.client ? <Typography>{row?.client?.displayName}</Typography> : <Typography>{row?.clientGroup?.displayName}<img src={mulitipleClient} width={"22px"} style={{ marginLeft: "10px", marginRight: "10px" }} alt="group" /></Typography>
      )
    },
    width: "200px"
  },
  {
    key: "category.name", title: "Service Category", render: (v) => (
      <Box display="flex">
        {getTitle(v?.tasks?.[0]?.category?.name)}
      </Box>
    ),
  },
  {
    key: "subCategory.name", title: "Service Sub-Category", render: (v) => (
      <Box display="flex">
        {getTitle(v?.tasks?.[0]?.subCategory?.name)}
      </Box>
    ),
  },
  {
    key: "name", title: "Service Name", default: true, render: (v) => (
      <Box display="flex">
        {getTitle(v?.tasks?.[0]?.service?.name)}
      </Box>
    ),
  },
  {
    key: "frequency",
    title: "Frequency",
    render: (item: any) => getTitle(item?.frequency)
  },
  {
    key: "priority",
    title: "Priority",
    render: (v) => (
      <Box display="flex">
        <PriorityText text={v?.tasks?.[0]?.priority} />
        {getTitle(v?.tasks?.[0]?.priority)}
      </Box>
    ),
  },
  {
    key: "taskLeader",
    title: "Task Leader",
    render: (v) => (
      <Members
        data={v?.tasks?.[0]?.taskLeader?.map((item: any) => ({
          title: item?.fullName,
          src: item?.imageStorage?.fileUrl,
        }))}
      />
    ),
  },
  {
    key: "Memberss",
    title: "User",
    render: (v) => (
      <Members
        data={v?.tasks?.[0]?.members?.map((item: any) => ({
          title: item?.fullName,
          src: item?.imageStorage?.fileUrl,
        }))}
      />
    ),
  },
];

export default Report;
