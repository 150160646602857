import React, { useState, useEffect } from 'react';
import { Box, Grid, MenuItem, Select, FormControl, InputLabel, Tooltip, Typography, Button, TextField, Autocomplete, Chip } from '@mui/material';
import { createClient, createClientGroup, getClients, getNonClientGroupClients } from 'api/services/clients/clients';
import { getUsers } from 'api/services/users';
import DrawerWrapper from 'components/DrawerWrapper';
import Loader from 'components/Loader';
import { snack } from 'components/toast';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { DialogProps, ResType } from 'types';
import TextFieldWithCountryCode from 'views/client-view/ProfileDetails/TextFieldWithCountryCode';
import { isValidMobile } from 'validations/phoneNumbr';
import { handleError } from 'utils/handleError';
import LoadingButton from 'components/LoadingButton';
import { getClientGroupPrefixId, getClientPrefixId } from 'api/services/invoice.preferencess';

interface Country {
    code: any;
    name: string;
    dialCode: string;
};

const AddClientGroup = ({ open, setOpen, watch, setValue }) => {
    const [state, setState] = useState<any>({ clientGroup: "", mobileNumber: "", countryCode: "IN", email: "", clientGroupNumber: "" });
    const [clients, setClients] = useState<any>([]);
    const queryClient = useQueryClient();

    const { data: clientPrefixId, isLoading: clientPrefixIdLoading }: ResType = useQuery("client-Prefix-Id", getClientGroupPrefixId, {
        enabled: open,
    });

    useEffect(() => {
        if (clientPrefixId) {
            setState({ ...state, clientGroupNumber: clientPrefixId?.data?.prefix });
        }
    }, [clientPrefixId, clientPrefixIdLoading]);

    const { data: clientsData, isLoading: clientsLoading }: ResType = useQuery(
        ["nonclientgroupclients", { query: { status: ['ACTIVE'] } }],
        getNonClientGroupClients,
        { enabled: open }
    );

    const { mutate, isLoading } = useMutation(createClientGroup, {
        onSuccess: (res: any) => {
            if (setValue) {
                setValue('clientGroup', res.data.id);
            }
            snack.success("Client Group Created");
            setOpen(false);
            setState({ clientGroup: "", mobileNumber: "", countryCode: "IN", email: "" });
            setClients([]);
            queryClient.invalidateQueries("clients-group");
        },
        onError: (err: any) => {
            console.log(err);
            snack.error(handleError(err));
        },
    });

    const counteyChange = (e: any) => {
        setState({
            ...state,
            ["countryCode"]: e
        });
    };

    const { data: users, isLoading: userLoading }: ResType = useQuery("users", getUsers, {
        enabled: open,
        onError: (err) => {
            if (!err?.error) {
                snack.error(`An error occurred while fetching user-data`);
            }
        }
    });

    const isValidEmail = (email) => {
        var emailPattern = /^[a-zA-Z0-9~!#$%^&*_+{}|?.'=`]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email);
    };

    const onFormSubmit = (e: any) => {
        e.preventDefault();
        if (!state?.clientGroup.trim().length) {
            snack.error("Invalid Display Name");
        } else if (state?.clientGroup.trim().length <= 2) {
            snack.error("Display Name Should be atleast 3 Characters !");
        } else if (!clients.length && !setValue) {
            snack.error("Please add Atleast one client !");
        } else if (!isValidEmail(state.email)) {
            snack.error("Invalid Email ID !");
        } else if (!isValidMobile(state.mobileNumber, state.countryCode)) {
            snack.error("Invalid Mobile number !");
        } else {
            mutate({ state, clients })
        }
    };

    const handleChange = (e: any) => {
        let { name, value } = e.target;
        setState({
            ...state,
            [name]: value,
        });
    };

    const handleClientGroupChange = (e) => {
        const { value } = e.target;

        const invalidChars = /[<>:"\/\\|?*.]/g;

        if (!invalidChars.test(value)) {
            setState({ ...state, clientGroup: value });
        } else {
            snack.error('Client Group Name cannot contain <, >, :, ", /, \\, |, ?, *, or .')
        }
    };

    return (
        <DrawerWrapper
            open={open}
            setOpen={() => {
                setOpen(false);
            }}
            title="Add Client Group"
        >
            {userLoading || clientsLoading ? (
                <Loader />
            ) : (
                <form>
                    <Grid item height={"100vh"} overflow={"scroll"} >
                        <Box mt={1}>
                            <TextField
                                fullWidth
                                required
                                size='small'
                                value={state.clientGroup}
                                label="Client Group Name"
                                name="clientGroup"
                                inputProps={{ minLength: 3, maxLength: 100 }}
                                onChange={handleClientGroupChange}
                            />
                        </Box>
                        <Box mt={2}>
                            <Autocomplete
                                size="small"
                                multiple
                                value={clients || []}
                                onChange={(_, value: any) => {
                                    setClients(value)
                                }}
                                options={clientsData?.data?.result || []}

                                getOptionLabel={(option: any) => {
                                    return option?.displayName;
                                }}
                                isOptionEqualToValue={(option: any, value: any) => {
                                    return option?.id === value?.id;
                                }}
                                renderInput={(params) => <TextField label=" Group Clients *" {...params} />}
                            />
                        </Box>
                        <Box mt={2}>
                            <TextField
                                fullWidth
                                size='small'
                                value={state.clientGroupNumber}
                                label="Client Group Number"
                                name="clientGroupNumber"
                                onChange={handleChange}
                                inputProps={{ minLength: 1, maxLength: 50 }}
                            />
                        </Box>
                        <Box mt={2}>
                            <TextFieldWithCountryCode
                                label="Mobile Number"
                                name="mobileNumber"
                                required
                                value={state?.mobileNumber || ""}
                                onChange={handleChange}
                                counteyChange={counteyChange}
                                country={state.countryCode}
                                inputProps={{ maxLength: 10 }}
                                error={!isValidMobile(state?.mobileNumber, state.countryCode) && state?.mobileNumber !== "" && state?.mobileNumber !== null}
                                helperText={(!isValidMobile(state?.mobileNumber, state.countryCode) && state?.mobileNumber !== "" && state?.mobileNumber !== null) && 'Invalid Mobile Number'}
                            />
                        </Box>
                        <Box mt={2}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name="email"
                                required
                                type="email"
                                onChange={handleChange}
                                size="small"
                                value={state.email}
                                label="Email ID"
                                error={state.email !== '' && !isValidEmail(state.email)}
                                helperText={state.email !== '' && !isValidEmail(state.email) && 'Invalid email address'}
                            />
                        </Box>
                        <Box mt={2}>
                            <LoadingButton
                                loading={isLoading}
                                disabled={isLoading}
                                onClick={onFormSubmit}
                                fullWidth
                                sx={{ mt: 3 }}
                                type="submit"
                                loadingColor="white"
                                title="Create Client Group"
                                color="secondary"
                            />
                        </Box>
                    </Grid>
                </form>
            )}
        </DrawerWrapper>
    );
}

export default AddClientGroup;
