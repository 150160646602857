import { Box } from "@mui/material";
import useTitle from "hooks/useTitle";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetFilters } from "redux/reducers/taskboardSlice";
import { useEffect } from "react";
import ClientNav from "./nav";
import { resetState } from "redux/reducers/clientsSlice";

function PreferencesLayout() {
    useTitle("Preferences");
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(resetFilters());
            dispatch(resetState());
        };
    }, []);

    return (
        <>
            <Box >
                <ClientNav />
                <Outlet />
            </Box>
        </>
    );
}

export default PreferencesLayout;