import { Box, Button, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Switch, Typography } from "@mui/material"
import { getTaskPreferences, updateTaskPreferences } from "api/services/invoice.preferencess";
import Loader from "components/Loader";
import { snack } from "components/toast";
import { UserProfileContext } from "context/UserProfile";
import { useContext, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { handleError } from "utils/handleError";

const TaskPreferences = () => {
    const queryClient = useQueryClient();
    const { data: uData } = useContext(UserProfileContext);
    const [task, setTask] = useState<any>({ taskDate: 15, colletData: "organization" });

    const { isLoading: queryLoading } = useQuery(["task-preferenecs", uData?.id], getTaskPreferences, {
        onSuccess: (res: any) => {
            setTask(res?.data?.taskPreferences);
        }
    })

    const { data, mutate, isLoading } = useMutation(updateTaskPreferences, {
        onSuccess: () => {
            snack.success("Task Preferences Updated!");
            queryClient.invalidateQueries("task-preferenecs");
        },
        onError: (err: any) => {
            snack.error(handleError(err));
        },
    });

    const handleUpdateTaskPrefernces = () => {
        const data = { ...task };
        // data.logHoursDate = data.loghours ? data.logHoursDate : 0;
        // if (data?.loghours) {
        //     if (!data?.logHoursDate) {
        //         return snack.error("Please Select Log Hours Date !")
        //     }
        // }
        mutate({ data: { taskPreferences: data } });
    }

    if (queryLoading) return <Loader />

    return <Box m={2}>
        <Typography variant="h5">
            Task Preferences
        </Typography>
        <Typography variant="body1" mt={2}>Upto How many Days Completed Tasks to be shown in Task Board and Dashboard?</Typography>
        <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Preferences</InputLabel>
            <Select
                size="small"
                placeholder="preferences"
                labelId="Prefenences"
                id="prefernces"
                value={task?.taskDate || null}
                onChange={(value) => {
                    setTask({ ...task, taskDate: value.target.value })
                }}
                label="preferences"
            >
                <MenuItem key={"one"} value={1}>
                    One Day
                </MenuItem>
                <MenuItem key={"week"} value={7}>
                    One Week
                </MenuItem>
                <MenuItem key={"15"} value={15}>
                    15 Days
                </MenuItem>
            </Select>
        </FormControl>
        {/* <Typography variant="body1" mt={5}>Should employees in the organisation be able to add log hours even after a task is completed ?</Typography>
        <Box mt={2}>
            <Box display="flex" mt={3} alignItems="center">
                <Switch
                    onChange={() => setTask({ ...task, loghours: !task?.loghours })}
                    checked={task?.loghours}
                    sx={{ marginRight: "25px" }}
                />
                <Typography>{task?.loghours ? "On" : "Off"}</Typography>
            </Box>
            {task?.loghours && <>
                <Typography sx={{ mt: 2 }}>How many days should employees be allowed to add log hours after completing a task ?</Typography>
                <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel>Log Hour Preferences</InputLabel>
                    <Select
                        size="small"
                        placeholder="loghourpreferences"
                        labelId="Log Hour Prefenences"
                        id="loghourprefernces"
                        value={task?.logHoursDate || null}
                        onChange={(value) => {
                            setTask({ ...task, logHoursDate: value.target.value })
                        }}
                        label="Log Hour Preferences"
                    >
                        <MenuItem key={"one"} value={1}>
                            One Day
                        </MenuItem>
                        <MenuItem key={"week"} value={7}>
                            One Week
                        </MenuItem>
                        <MenuItem key={"15"} value={15}>
                            15 Days
                        </MenuItem>
                    </Select>
                </FormControl></>}
        </Box> */}
        <Typography variant="h5" mt={3}>
            Task Collect Data Preferences
        </Typography>
        <Typography variant="body1" mt={2}>Who's Contact Details to be visible in I-Pro Client Link ?</Typography>
        <FormControl>
            <RadioGroup
                value={task?.colletData || "organization"}
                onChange={(value) => {
                    setTask({ ...task, colletData: value.target.value })
                }}
                row
            >
                <FormControlLabel value="organization" control={<Radio />} label="Organization" />
                <FormControlLabel value="user" control={<Radio />} label="User" />
            </RadioGroup>
        </FormControl>
        <Box>
            <Button variant="contained" disabled={isLoading} sx={{ mt: 2 }} color="secondary" onClick={handleUpdateTaskPrefernces}>Update</Button>
        </Box>
    </Box>
}

export default TaskPreferences