import { Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { StyledProfileNavItem } from "views/clients/styles";


function PreferencesNav() {
    const location = useLocation();
    const navigate = useNavigate();
    const handleClick = (path) => {
        navigate(path);
    };


    return (
        <>
            <Box
                mb={1}
                display="flex"
                gap="15px"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                bgcolor="#F5F5F5"
            >
                <StyledProfileNavItem
                    variant="body1"
                    color="rgba(0,0,0,0.7)"
                    active={location.pathname === `/settings/task-preference` ? 1 : 0}
                    onClick={() => handleClick(`/settings/task-preference`)}
                >
                    Task
                </StyledProfileNavItem>
                <StyledProfileNavItem
                    variant="body1"
                    color="rgba(0,0,0,0.7)"
                    active={location.pathname === `/settings/task-preference/loghour` ? 1 : 0}
                    onClick={() => handleClick(`/settings/task-preference/loghour`)}
                    sx={{ marginLeft: "20px", }}
                >
                    Time Sheet
                </StyledProfileNavItem>
            </Box>
        </>

    );
}

export default PreferencesNav;
